import React, { useContext, useEffect, useState } from "react";
import useCampaignMetricFetch, {
  IUseCampaignMetricResponse,
} from "../../hooks/useCampaignMetricFetch/useCampaignMetricFetch";
import AdsDashBoardWidgetActive from "../AdsDashBoardWidgetActive/AdsDashBoardWidgetActive";
import AdsDashBoardWidgetInActive from "../AdsDashBoardWidgetInActive/AdsDashBoardWidgetInActive";
import AdsWidgetLoader from "../AdsWidgetLoader/AdsWidgetLoader";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";

const AdsDashBoardWidget: React.FC = () => {
  const {
    profileInfo,
    userCampaignInfo,
    userCampaignInfoLoading,
    userCompleteCampaignInfo,
    userCompleteCampaignInfoLoading,
  } = useContext(DesktopContext);

  const campaignId = userCompleteCampaignInfo?.campaign_id;

  const googleAdsAccountId = profileInfo?.google_ads_account_id;
  const { campaignMetricDataLoading }: IUseCampaignMetricResponse =
    useCampaignMetricFetch({
      campaignId,
      googleAdsAccountId,
    });
  const [isCampaignActive, setIsCampaignActive] = useState<boolean>(false);

  useEffect(() => {
    if (userCampaignInfo && userCompleteCampaignInfo) {
      if (userCampaignInfo?.length > 0) {
        setIsCampaignActive(true);
      }
    }
  }, [
    userCampaignInfo,
    userCampaignInfoLoading,
    userCompleteCampaignInfoLoading,
  ]);

  if (
    userCampaignInfoLoading ||
    userCompleteCampaignInfoLoading ||
    campaignMetricDataLoading
  ) {
    return <AdsWidgetLoader />;
  }

  return (
    <>
      {isCampaignActive && <AdsDashBoardWidgetActive />}
      {!isCampaignActive && <AdsDashBoardWidgetInActive />}
    </>
  );
};

export default AdsDashBoardWidget;
