import React, { useContext } from "react";
import Image from "next/future/image";
import "chartjs-adapter-date-fns";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { AnalyticsType } from "../../contexts/types";
import GenericBadge from "../uiwrappers/GenericBadge/GenericBadge";
import AnalyticsChartWithRechart from "../AnalyticsChart/AnalyticsChartWithRechart";
import { bodyContent } from "./AnalyticsWidget";

const AnalyticsWidgetBody = ({
  type,
  formattedLongTimePeriod,
}: {
  type: AnalyticsType;
  formattedLongTimePeriod: string;
}) => {
  const {
    basicInfo,
    googleSearchKeyWords,
    gscImpressions,
    googleImpressionsCount,
    numberOfWeeks,
  } = useContext(DesktopContext);

  return (
    <div
      className={`flex ${
        type == AnalyticsType.MapPack ? "md:flex-row" : ""
      } flex-col gap-4 mt-5`}
    >
      <div
        className={`flex-1 w-full ${
          type == AnalyticsType.MapPack ? "md:w-1/2" : ""
        }`}
      >
        <div className="flex items-center gap-2.5">
          <div className="w-8 h-8 p-1 bg-white rounded-lg border border-gray-200 justify-center items-center inline-flex">
            <Image
              layout="raw"
              src="/google-logo.png"
              alt="Google Logo"
              width={20}
              height={20}
            />
          </div>
          <span className="text-gray-900 font-bold text-lg">
            {bodyContent.get(type)?.title}
          </span>
        </div>
        <span className="mt-1.5 text-gray-600 text-sm">
          {bodyContent.get(type)?.body}{" "}
          <strong className="font-medium">
            {type != AnalyticsType.Keywords && formattedLongTimePeriod}
          </strong>
        </span>
        {type == AnalyticsType.MapPack && (
          <div className="mt-4 flex flex-row gap-6">
            <div>
              <h1
                className={
                  googleImpressionsCount && googleImpressionsCount >= 0
                    ? "text-gray-900 text-2xl font-semibold"
                    : "h-8 w-8 animate-pulse bg-gray-200"
                }
              >
                {googleImpressionsCount}
              </h1>
              <span className="mt-1 text-gray-500 text-sm font-medium">
                Appearances
              </span>
            </div>
            <div className="border h-auto"></div>
            <div>
              <h1
                className={
                  !!googleImpressionsCount && !!numberOfWeeks
                    ? "text-gray-900 text-2xl font-semibold"
                    : "h-8 w-8 animate-pulse bg-gray-200"
                }
              >
                {Math.floor((googleImpressionsCount || 0) / numberOfWeeks)}
              </h1>
              <span className="mt-1 text-gray-500 text-sm font-medium">
                Weekly Avg.
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        className={`flex-1 w-full ${
          type == AnalyticsType.MapPack ? "md:w-1/2" : ""
        }`}
      >
        {type == AnalyticsType.MapPack && (
          // <AnalyticsChart data={gscImpressions} />
          <AnalyticsChartWithRechart data={gscImpressions} />
        )}
        {type == AnalyticsType.Keywords && (
          <div className="flex flex-row gap-1.5 flex-wrap">
            {basicInfo?.services &&
              basicInfo.services.map((service) => (
                <GenericBadge
                  key={service["service-name"]}
                  text={service["service-name"]}
                  color={"gray"}
                  textSize="sm"
                />
              ))}
            {!!googleSearchKeyWords.size && (
              <p className="text-gray-600 text-sm font-normal">
                and {googleSearchKeyWords.size} other unique keywords
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsWidgetBody;
