import { useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";

import { saveNewMonthlyGoal } from "../DashboardPage/utils";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";

interface MonthlyGoalModalProps {
  monthlyGoalModalOpen: boolean;
  setMonthlyGoalModalOpen: (arg0: boolean) => void;
  monthlyGoal: any;
  setMonthlyGoal: (arg0: any) => void;
  onChangeHandler: (arg0: any) => void;
  setMonthlyGoalInteger: (arg0: any) => void;
}

export default function MonthlyGoalModal({
  monthlyGoalModalOpen,
  setMonthlyGoalModalOpen,
  monthlyGoal,
  setMonthlyGoal,
  onChangeHandler,
  setMonthlyGoalInteger,
}: MonthlyGoalModalProps) {
  const { setProfileInfo, profileInfo } = useContext(DesktopContext);
  const [loading, setLoading] = useState(false);

  async function onSaveClickHandler() {
    setLoading(true);
    await saveNewMonthlyGoal(monthlyGoal);
    setMonthlyGoalInteger(parseInt(monthlyGoal));
    setMonthlyGoalModalOpen(false);
    setLoading(false);

    if (profileInfo) {
      setProfileInfo({
        ...profileInfo,
        review_monthly_goal: parseInt(monthlyGoal),
      });
    }
  }

  function handleModalClose() {
    setMonthlyGoalModalOpen(false);
  }

  return (
    <div className="sm:w-700">
      <UtilityModal
        title="Set Goal"
        subTitle=""
        isOpen={monthlyGoalModalOpen}
        setIsOpen={setMonthlyGoalModalOpen}
        onCancelHandler={handleModalClose}
        submitButtonText="Save"
        isSubmitDisabled={false}
        onSubmitHandler={onSaveClickHandler}
        showCancelButton={true}
        showSubmitButton={true}
        showLoader={true}
        fullWidth={false}
        loading={loading}
      >
        <div>
          <label htmlFor="email" className="block text-sm-medium text-gray-700">
            Monthly Goal
          </label>
          <div className="mt-1">
            <input
              pattern="\d*"
              type="text"
              name="email"
              id="email"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder=""
              value={monthlyGoal?.toString()}
              onChange={onChangeHandler}
            />
          </div>
          <div className="text-gray-500 text-sm-normal mt-1">
            Topline Pro Tip: Collecting at least 10 reviews a month helps you
            rank higher
          </div>
        </div>
      </UtilityModal>
    </div>
  );
}
