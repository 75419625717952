import React from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import { useRouter } from "next/router";

function IntercomWidget() {
  const isMobile = useIsMobile();
  const router = useRouter();
  const isHome = router.pathname === "/home";

  return (
    <div>
      {isHome && (
        <div
          className={
            isMobile
              ? `fixed bottom-24 right-4 z-10`
              : "fixed bottom-12 right-4 z-10"
          }
        >
          <PrimaryButton
            text=""
            size={isMobile ? "icon only lg" : "icon only xl"}
            customStyle={`rounded-full bg-caro-orange border-caro-orange text-white hover:bg-caro-orange-hover focus:outline-none focus:border-caro-orange transition-colors duration-150 ease-in-out`}
            id="intercom-launcher"
            icon="chat-alt-2"
            iconPlacement="leading"
            orange={true}
          />
        </div>
      )}
    </div>
  );
}

export default IntercomWidget;
