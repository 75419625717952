import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useIsMobile from "../../../../hooks/useIsMobile";
import { XIcon } from "@heroicons/react/solid";
import LoadingSpinner from "../../../uiwrappers/LoadingSpinner/LoadingSpinner";

export default function ReferAFriendPopup(): React.ReactElement {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const isMobile = useIsMobile();
  const modalHeight = isMobile ? 350 : 450;

  useEffect(() => {
    getViralLoopsWidget();
  }, []);

  const getViralLoopsWidget = () => {
    const viralLoopsScriptTag = document.createElement("script");
    viralLoopsScriptTag.innerHTML = `!function(){var a=window.VL=window.VL||{};return a.instances=a.instances||{},a.invoked?void(window.console&&console.error&&console.error("VL snippet loaded twice.")):(a.invoked=!0,void(a.load=function(b,c,d){var e={};e.publicToken=b,e.config=c||{};var f=document.createElement("script");f.type="text/javascript",f.id="vrlps-js",f.defer=!0,f.src="https://app.viral-loops.com/client/vl/vl.min.js";var g=document.getElementsByTagName("script")[0];return g.parentNode.insertBefore(f,g),f.onload=function(){a.setup(e),a.instances[b]=e},e.identify=e.identify||function(a,b){e.afterLoad={identify:{userData:a,cb:b}}},e.pendingEvents=[],e.track=e.track||function(a,b){e.pendingEvents.push({event:a,cb:b})},e.pendingHooks=[],e.addHook=e.addHook||function(a,b){e.pendingHooks.push({name:a,cb:b})},e.$=e.$||function(a){e.pendingHooks.push({name:"ready",cb:a})},e}))}();var campaign=VL.load("EgDGYLZvdpkENfl10PvfKTcLcs4",{autoLoadWidgets:!0});`;
    setTimeout(() => {
      document.body.appendChild(viralLoopsScriptTag);
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:px-6 sm:pt-10 sm:pb-6 -mt-16`}
                  style={{ height: modalHeight }}
                >
                  <button
                    type="button"
                    className="absolute top-0 right-0 pt-4 pr-4 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="mt-3 text-center">
                    <h3 className="mt-3 mb-1 text-h2-bold text-gray-900">
                      Refer a friend
                    </h3>
                    <h5 className="text-sm-normal text-gray-900">
                      For each friend you refer, you&apos;ll both earn $100.
                    </h5>
                    {loading && (
                      <div className="mt-8">
                        <LoadingSpinner color="blue" />
                      </div>
                    )}
                    <div data-vl-widget="embedForm"></div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
