import React, { FC } from "react";
import WidgetContainer from "../primaryrender/dashboard/WidgetContainer/WidgetContainer";
import WidgetHeader from "../WidgetHeader/WidgetHeader";
import WidgetHeading from "../WidgetHeading/WidgetHeading";
import PrimaryButton from "../uiwrappers/PrimaryButton/PrimaryButton";
import { useRouter } from "next/router";
import WidgetFeature from "../WidgetFeature/WidgetFeature";
import WidgetBody from "../WidgetBody/WidgetBody";
import WidgetFeatureSection from "../WidgetFeatureSection/WidgetFeatureSection";
import {
  ArrowCircleUpIcon,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import WidgetSubscriptionInfoSection from "../WidgetSubscriptionInfoSection/WidgetSubscriptionInfoSection";
import GenericContainer from "../common/GenericContainer/GenericContainer";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import FreeAnalysisTagLine from "../primaryrender/marketing/FreeAnalysisTagLine/FreeAnalysisTagLine";
import useChurnModal from "../../hooks/useChurnModal/useChurnModal";

const AdsDashBoardWidgetInActive: FC = () => {
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();
  const router = useRouter();
  const reRouteToAds = () => {
    if (shouldOpenChurnModal()) return;
    router.push("/marketing/createCampaign");
  };
  return (
    <WidgetContainer>
      <WidgetHeader>
        <WidgetHeading>Run Google Ads Through Topline Pro</WidgetHeading>
        <div className="mt-2 sm:mt-0">
          <PrimaryButton
            onClickFunc={reRouteToAds}
            size="md"
            text="Get Started"
          />
        </div>
      </WidgetHeader>
      {renderChurnModal()}

      <WidgetBody>
        <WidgetFeatureSection bgColor="bg-purple-600">
          <WidgetFeature
            feature="Top Google ads placement"
            FeatureIcon={ArrowCircleUpIcon}
          />
          <WidgetFeature
            feature="Less than 5 minute setup"
            FeatureIcon={ClockIcon}
          />
          <WidgetFeature
            feature="Clear performance reports"
            FeatureIcon={ChartBarIcon}
          />
          <WidgetFeature
            feature="Start and stop whenever"
            FeatureIcon={CalendarIcon}
          />
        </WidgetFeatureSection>
        <WidgetSubscriptionInfoSection>
          <GenericContainer customStyle="p-3 sm:p-5 sm:w-40  shadow">
            <div className=" flex space-x-1">
              <div className="text-h3-bold text-gray-900">$150</div>
              <div className="text-h3-normal text-gray-900">/ mo</div>
            </div>
            <div className="text-body-normal whitespace-nowrap text-gray-900">
              Management Fee
            </div>
            <div className="text-sm-normal text-gray-600">for Topline Pro</div>
          </GenericContainer>
          <div className="text-h3-bold text-gray-900 sm:mx-4 flex items-center">
            +
          </div>
          <GenericContainer customStyle="p-3 sm:p-5 sm:w-40  shadow">
            <div className="text-h3-bold text-gray-900">Budgeted</div>
            <div className="text-body-normal text-gray-900">Ad Spend</div>
            <div className="text-sm-normal text-gray-600">for Google</div>
          </GenericContainer>
        </WidgetSubscriptionInfoSection>
      </WidgetBody>
      <WidgetFooter>
        <div className="flex items-center  text-gray-500 sm:w-auto w-full">
          <FreeAnalysisTagLine clickHereCss="text-blue-600 sm:ml-3" />
        </div>
      </WidgetFooter>
    </WidgetContainer>
  );
};

export default AdsDashBoardWidgetInActive;
