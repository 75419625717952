import { useState } from "react";
import ViewAssetModal from "../../../common/ViewAssetModal/ViewAssetModal";
import { timeSince } from "../../editor/utils";
import { formatDateTime } from "../../reviews/ReviewContainer/utils";

interface SocialMediaTileProps {
  post: any;
  index: number;
  message: string;
  origin: any;
  created_time: any;
  photoUrl: any;
  section: string;
  totalPostsInSection: number;
}

export default function SocialMediaTile({
  index,
  message,
  origin,
  created_time,
  photoUrl,
  section,
  totalPostsInSection,
}: SocialMediaTileProps) {
  let borderStyle;
  const [viewModalOpen, setViewModalOpen] = useState(false);
  if (section === "recent-posts" && index === 0 && totalPostsInSection === 1) {
    borderStyle = "border-none";
  } else if (
    section === "recent-posts" &&
    index === 0 &&
    totalPostsInSection === 2
  ) {
    borderStyle = "border-b border-gray-200";
  } else if (
    section === "scheduled-posts" &&
    index === 0 &&
    totalPostsInSection === 1
  ) {
    borderStyle = "border-none";
  } else if (
    section === "scheduled-posts" &&
    index === 0 &&
    totalPostsInSection === 2
  ) {
    borderStyle = "border-b border-gray-200";
  } else {
    borderStyle = "border-none";
  }

  return (
    <>
      <div className={`flex py-4 ${borderStyle} w-full`}>
        <div className="flex flex-row w-full justify-between items-center">
          <div className={`flex flex-col ${photoUrl ? "w-2/3" : "w-full"}`}>
            <span
              style={{ whiteSpace: "pre-line" }}
              className="text-sm-normal text-gray-600 mb-1 break-words"
            >
              {message}
            </span>

            <div className="flex flex-row space-x-1 items-center w-full">
              {origin?.facebook && (
                <div
                  className="inline text-gray-400"
                  data-cy="facebook-post-icon"
                >
                  <svg
                    className="inline h-5 w-5 -mt-1"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
              {origin?.google && (
                <div
                  className="inline text-gray-400"
                  data-cy="google-post-icon"
                >
                  <svg
                    className="inline h-5 w-5 -mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    fill="currentColor"
                  >
                    <path d="M 15.003906 3 C 8.375 3 3 8.371094 3 15 C 3 21.628906 8.375 27 15.003906 27 C 25.015625 27 27.269531 17.707031 26.328125 13 L 15 13 L 15 17 L 22.738281 17 C 21.847656 20.449219 18.726562 23 15 23 C 10.582031 23 7 19.417969 7 15 C 7 10.582031 10.582031 7 15 7 C 17.007812 7 18.839844 7.746094 20.246094 8.96875 L 23.085938 6.128906 C 20.953125 4.183594 18.117188 3 15.003906 3 Z M 15.003906 3 " />
                  </svg>
                </div>
              )}
              {origin?.instagram && (
                <div
                  className="inline text-gray-400"
                  data-cy="instagram-post-icon"
                >
                  <svg
                    className="inline h-5 w-5 -mt-1"
                    fill="currentColor"
                    width="20px"
                    height="20px"
                    viewBox="0 0 256 256"
                    id="Flat"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z" />
                  </svg>
                </div>
              )}
              <div className="text-xs-normal text-gray-400">
                {section === "scheduled-posts" &&
                  `Posting ${formatDateTime(new Date(created_time))}`}
                {section === "recent-posts" &&
                  `Posted ${timeSince(new Date(created_time))} ago`}
              </div>
            </div>
          </div>
          <div>
            {photoUrl && (
              <img
                className="flex w-20 rounded-lg object-cover cursor-pointer"
                src={photoUrl}
                data-cy="post-photo"
                alt="post"
                referrerPolicy="no-referrer"
                onClick={() => {
                  setViewModalOpen(true);
                }}
              />
            )}
          </div>
          <ViewAssetModal
            open={viewModalOpen}
            setOpen={setViewModalOpen}
            asset={photoUrl}
          />
        </div>
      </div>
    </>
  );
}
