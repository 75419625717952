import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import { useRouter } from "next/router";
import PaymentsSetupGraphic from "../PaymentsSetupGraphic/PaymentsSetupGraphic";

export default function PaymentsWidgetNeedToSetup() {
  const router = useRouter();

  async function connectToStripe() {
    router.push("/payments-start");
  }

  return (
    <>
      <div>
        <div className="flex flex-col w-full items-center pb-1">
          <div className="flex sm:flex-row flex-col justify-between w-full">
            <h1 className="text-h2-bold text-gray-900">
              Get paid on Topline Pro
            </h1>
            <div className="mt-2 sm:mt-0">
              <PrimaryButton
                onClickFunc={connectToStripe}
                size="md"
                text="Get Started"
              />
            </div>
          </div>
          <PaymentsSetupGraphic />
        </div>
      </div>
    </>
  );
}
