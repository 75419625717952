import React, { useContext, useState } from "react";
import ConnectionsBox from "../../connections/ConnectionsBox/ConnectionsBox";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { CONTACTS_SUBHEADER } from "../../reviews/CompleteSetupWidget/utils";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import ConnectionStatusBadge from "../../../checkout/ConnectionStatusBadge/ConnectionStatusBadge";
import { useRouter } from "next/router";
import { useAuthFacebook } from "../../../../hooks/useAuthFacebook";
import { facebookToast } from "../../../../hooks/utils";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import { useAuthGoogle } from "../../../../hooks/useAuthGoogle";
import { useAuthInstagram } from "../../../../hooks/useAuthInstagram";
import { useContacts } from "../../../../hooks/useContacts/useContacts";

function DashboardConnections() {
  const {
    profileInfo,
    isFacebookAuthenticated,
    isGmbAuthenticated,
    isQuickbooksAuthenticated,
    googleContactsAuthenticated,
    basicInfo,
    isInstagramAuthenticated,
  } = useContext(DesktopContext);

  const { setCsvUploadModalOpen, renderCsvModal } = useContacts();
  const router = useRouter();
  const { handleLogin } = useAuthInstagram();
  const { slug } = profileInfo || {};
  const {
    connectToFacebook,
    renderFacebookButton,
    renderFacebookMultiPageModal,
  } = useAuthFacebook(slug);

  const { startGoogleLogin } = useAuthGoogle(slug);
  const [loading, setLoading] = useState<boolean>(false);

  function renderFbButton() {
    if (basicInfo?.fb_tokens_expired) {
      return getFacebookLoginButton("Reconnect");
    }

    if (isFacebookAuthenticated) {
      return <ConnectionStatusBadge isConnected={true} size="sm" />;
    } else {
      return getFacebookLoginButton();
    }
  }

  const getFacebookLoginButton = (buttonText = "Connect") => {
    if (!router.query.code) {
      const callbackFunction = async (response) => {
        const status = await connectToFacebook(response);
        facebookToast(status);
      };
      const customButton = (renderProps) => (
        <PrimaryButton
          text={buttonText}
          size="md"
          onClickFunc={renderProps.onClick}
        />
      );
      return renderFacebookButton(callbackFunction, customButton);
    } else {
      return <SecondaryButton text={buttonText} size="md" />;
    }
  };

  function renderInstagramButton() {
    if (isInstagramAuthenticated) {
      return <ConnectionStatusBadge isConnected={true} size="sm" />;
    } else {
      return getInstagramLoginButton();
    }
  }

  const getInstagramLoginButton = (buttonText = "Connect") => {
    if (!router.query.code) {
      return (
        <PrimaryButton text="Connect" onClickFunc={handleLogin} size="md" />
      );
    } else {
      return <SecondaryButton text={buttonText} size="md" />;
    }
  };

  function renderContactsButton() {
    if (isQuickbooksAuthenticated || googleContactsAuthenticated)
      return <ConnectionStatusBadge isConnected={true} size="sm" />;
    else
      return (
        <PrimaryButton
          text="Connect"
          size="md"
          onClickFunc={() => {
            setCsvUploadModalOpen(true);
          }}
          loading={loading}
          customStyle={loading ? "w-24" : ""}
        />
      );
  }

  function renderGmbButton() {
    if (isGmbAuthenticated) {
      return <ConnectionStatusBadge isConnected={true} size="sm" />;
    } else
      return (
        <PrimaryButton
          text="Connect"
          size="md"
          onClickFunc={startGoogleLogin}
          loading={loading}
          customStyle={loading ? "w-24" : ""}
        />
      );
  }

  return (
    <div className="h-full">
      {renderFacebookMultiPageModal()}
      <div className="flex flex-col justify-between w-full sm:gap-7 sm:mt-6 gap-4 mt-4">
        <ConnectionsBox
          header="Facebook"
          subheader="Share photos once, see them everywhere"
          renderButton={renderFbButton}
          icon="facebook-with-border"
        />
        {basicInfo?.fb_tokens_expired && (
          <div className="text-sm-normal text-red-600 -mt-1 mb-2">
            Your Facebook connection has expired. Please reconnect your account.
          </div>
        )}

        <ConnectionsBox
          header="Google Business Profile"
          subheader="Get reviews and move up the Google rankings"
          renderButton={renderGmbButton}
          icon="google-with-border"
        />

        <ConnectionsBox
          header="Connect Contact Source"
          subheader={CONTACTS_SUBHEADER}
          renderButton={renderContactsButton}
          icon="contacts-with-border"
        />
        {renderCsvModal()}

        <ConnectionsBox
          header="Instagram"
          subheader="Promote your business and drive engagement"
          renderButton={renderInstagramButton}
          icon="instagram-with-border"
        />
      </div>
    </div>
  );
}

export default DashboardConnections;
