import React from "react";

interface StatsCardProps {
  children: React.ReactNode;
}
const StatsCardWrapper: React.FC<StatsCardProps> = ({ children }) => {
  return (
    <div className="w-full  p-4 h-24  overflow-x-auto no-scrollbar bg-white   justify-start items-center gap-5 inline-flex">
      {children}
    </div>
  );
};

export default StatsCardWrapper;
