import Link from "next/link";
import { LightningBoltIcon } from "@heroicons/react/solid";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import FormattedDateAndStarRating from "../../reviews/FormattedDateAndStarRating/FormattedDateAndStarRating";
import { Review } from "../../../../contexts/types";
import useIsMobile from "../../../../hooks/useIsMobile";

interface ShowcasesWidgetProps {
  reviewsWithoutShowcases: Review[];
}

export const ShowcasesWidget: React.FC<ShowcasesWidgetProps> = ({
  reviewsWithoutShowcases,
}) => {
  const isMobile = useIsMobile();
  return (
    <WidgetContainer>
      <div className="flex flex-col items-start">
        <h1 className="text-h2-bold text-gray-900">Showcases</h1>
        <div className="text-sm-normal text-gray-600">
          Showcase recent work to build trust and rank higher in your area
        </div>
        <div className="flex items-center mt-2">
          <LightningBoltIcon className="text-blue-600 h-4 w-4 mr-1.5" />
          <div className="text-body-medium text-gray-900">
            Start from recent 5-star reviews
          </div>
        </div>
      </div>
      {reviewsWithoutShowcases?.length > 0 && (
        <div className="bg-white rounded-lg py-4 mt-4">
          <div className="flex overflow-x-auto pt-2.5 pl-4 space-x-4">
            {reviewsWithoutShowcases?.map((review, index) => {
              return (
                <div
                  key={index}
                  className="min-w-[250px] max-w-[250px] p-4 border border-gray-200 rounded-lg"
                >
                  <FormattedDateAndStarRating
                    review={review}
                    reviewContentStyle="truncate"
                    fontSize="sm"
                    showCreate
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex justify-center mt-3">
        <Link href="/site/showcases">
          <a>
            <PrimaryLink
              size={isMobile ? "sm" : "md"}
              text={`View all showcases`}
              icon="right-arrow"
              iconPlacement="lagging"
              customStyle="font-medium"
            />
          </a>
        </Link>
      </div>
    </WidgetContainer>
  );
};
