import React, { useState } from "react";
import {
  getCampaignMetrics,
  getTimePeriod,
} from "../../components/GoogleAdsDashBoard/CampaignHistoricMetrics/utils";
import useSWR from "swr";
import { DAY_IN_MILLISECONDS } from "../../helpers/common";

export type IUseCampaignMetricResponse = {
  campaignMetricData: any;
  campaignMetricDataLoading: boolean;
  campaignMetricDataError: any;
  mutateCampaignMetricData: (
    _data?: any,
    _shouldRevalidate?: boolean
  ) => Promise<any>;
  setCampaignMeticTimePeriod: React.Dispatch<React.SetStateAction<string>>;
};

const fetchMetrics = async (
  campaignId: string,
  googleAdsAccountId: string,
  timePeriod: string
) => {
  const time_period = getTimePeriod(timePeriod);
  const message = await getCampaignMetrics(
    campaignId,
    googleAdsAccountId,
    time_period
  );
  return message;
};

const useCampaignMetricFetch = ({
  campaignId,
  googleAdsAccountId,
  defaultTimePeriod = "Last 30 days",
}: {
  campaignId: string | null;
  googleAdsAccountId: string | null;
  defaultTimePeriod?: string;
}): IUseCampaignMetricResponse => {
  const [campaignMeticTimePeriod, setCampaignMeticTimePeriod] =
    useState(defaultTimePeriod);

  const {
    data: campaignMetricData,
    isLoading: campaignMetricDataLoading,
    error: campaignMetricDataError,
    mutate: mutateCampaignMetricData,
  } = useSWR(
    [
      campaignId,
      googleAdsAccountId,
      campaignMeticTimePeriod,
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/ads/get-campaign-metrics-by-time-period`,
    ],
    () => {
      if (campaignId && googleAdsAccountId) {
        return fetchMetrics(
          campaignId,
          googleAdsAccountId,
          campaignMeticTimePeriod
        );
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      refreshInterval: DAY_IN_MILLISECONDS,
    }
  );

  const hookContext: IUseCampaignMetricResponse = {
    campaignMetricData,
    campaignMetricDataLoading,
    campaignMetricDataError,
    mutateCampaignMetricData,
    setCampaignMeticTimePeriod,
  };
  return hookContext;
};

export default useCampaignMetricFetch;
