import React from "react";
import "chartjs-adapter-date-fns";
import { LightningBoltIcon } from "@heroicons/react/solid";
import PrimaryButton from "../uiwrappers/PrimaryButton/PrimaryButton";
import { useRouter } from "next/router";
import { AnalyticsType } from "../../contexts/types";
import { footerContent } from "./AnalyticsWidget";

const AnalyticsWidgetFooter = ({ type }: { type: AnalyticsType }) => {
  const router = useRouter();

  return (
    <div className="mt-6 bg-blue-50 -mx-6 -mb-6 px-6 py-6 md:pt-5 md:pb-4 border border-blue-100 rounded-b-lg">
      <div className="flex md:flex-row flex-col items-center justify-between gap-4">
        <div className="w-full">
          <div className="flex items-center gap-2">
            <LightningBoltIcon className="w-5 h-5 text-blue-600 relative" />
            <span className="text-sm font-semibold text-gray-700">
              {footerContent.get(type)?.title}
            </span>
          </div>
          <span className="text-gray-600 text-sm font-normal mt-1">
            {footerContent.get(type)?.body}
          </span>
        </div>
        <div className="w-full md:w-32">
          {type == AnalyticsType.MapPack && (
            <PrimaryButton
              onClickFunc={() =>
                window.open(
                  "https://help.toplinepro.com/en/articles/8887910-understanding-google-maps-for-local-search",
                  "_blank"
                )
              }
              size="sm"
              text="Learn More"
              customStyle="w-full md:w-32"
            />
          )}
          {type == AnalyticsType.Keywords && (
            <PrimaryButton
              onClickFunc={() => router.push("/site/services")}
              size="sm"
              text="Update Services"
              customStyle="w-full md:w-32"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsWidgetFooter;
