import React, { useContext } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { unformatPhoneNumber } from "../../contacts/helpers/formatPhoneNumber";
import {
  getAvatar,
  getCustomerDisplayName,
  numberWithCommas,
} from "../../payments/utils";
import moment from "moment";

interface PaymentsWidgetTileProps {
  invoice: any;
  type: string;
  section: string;
  index: number;
  totalInvoicesInSection: any;
}

export default function PaymentsWidgetTile({
  invoice,
  type,
  section,
  index,
  totalInvoicesInSection,
}: PaymentsWidgetTileProps) {
  const { contacts } = useContext(DesktopContext);
  let contact,
    firstName,
    lastName,
    contact2,
    dateCreated,
    amount,
    customerName,
    customerNameDisplayed;

  if (type === "invoice") {
    contact = contacts?.find((c) => {
      let phone;
      if (c.phone_number) {
        const unformattedPhone = unformatPhoneNumber(c.phone_number);
        if (unformattedPhone?.includes("+1")) {
          phone = unformattedPhone;
        } else {
          phone = "+1" + unformattedPhone;
        }
      }
      const customerName = `${c.first_name} ${c.last_name}`.trim();

      return (
        customerName === invoice.customer_name &&
        (phone === invoice.customer_phone || c.email === invoice.customer_email)
      );
    });

    firstName = invoice?.customer_name?.split(" ")[0] || "";
    lastName = invoice?.customer_name?.split(" ")[1] || "";
    contact2 = { first_name: firstName, last_name: lastName };
    dateCreated = moment.unix(invoice.created).format("MMM D, YYYY");
    amount = (invoice.amount_due / 100).toFixed(2);
    customerName = invoice.customer_name;
    customerNameDisplayed = getCustomerDisplayName(customerName);
  } else if (type === "customemr-chooses-price") {
    const billingDetails = invoice.charges.data[0].billing_details;
    amount = (invoice.amount_received / 100).toFixed(2);
    const customerEmail = billingDetails.email;
    const customerName = billingDetails.name;
    const customerPhone = billingDetails.phone;
    dateCreated = moment.unix(invoice.created).format("MMM D, YYYY");
    const firstName = customerName.split(" ")[0] || "";
    const lastName = customerName.split(" ")[1] || "";

    contact = contacts?.find((c) => {
      let phone;
      if (c.phone_number) {
        const unformattedPhone = unformatPhoneNumber(c.phone_number);
        if (unformattedPhone?.includes("+1")) {
          phone = unformattedPhone;
        } else {
          phone = "+1" + unformattedPhone;
        }
      }
      const customerNameFromContacts = `${c.first_name} ${c.last_name}`.trim();

      return (
        customerNameFromContacts === customerName &&
        (phone === customerPhone || c.email === customerEmail)
      );
    });

    contact2 = { first_name: firstName, last_name: lastName };
  }

  let borderStyle;
  if (
    section === "open-invoices" &&
    index === 0 &&
    totalInvoicesInSection === 1
  ) {
    borderStyle = "border-none";
  } else if (
    section === "open-invoices" &&
    index === 0 &&
    totalInvoicesInSection === 2
  ) {
    borderStyle = "border-b border-gray-200";
  } else if (
    section === "paid-invoices" &&
    index === 0 &&
    totalInvoicesInSection === 1
  ) {
    borderStyle = "border-none";
  } else if (
    section === "paid-invoices" &&
    index === 0 &&
    totalInvoicesInSection === 2
  ) {
    borderStyle = "border-b border-gray-200";
  } else {
    borderStyle = "border-none";
  }

  return (
    <>
      <div className={`flex py-4 ${borderStyle} w-full`}>
        <div className="flex flex-row w-full items-center">
          <div className="flex w-12 h-12 mr-4">
            {contact
              ? getAvatar(contact, "w-12", "h-12")
              : getAvatar(contact2, "w-12", "h-12")}
          </div>
          <div className={`flex flex-row w-full items-center text-sm-normal`}>
            <div className={`flex flex-row w-full items-center`}>
              <div className={`flex flex-col justify-center`}>
                <div className={`text-sm-medium text-gray-900`}>
                  {customerNameDisplayed}
                </div>
                <div className="flex text-gray-500">{dateCreated}</div>
              </div>
            </div>
          </div>

          <div className="flex text-gray-800 text-sm-medium sm:mr-4 mr-2">
            ${numberWithCommas(amount)}
          </div>
        </div>
      </div>
    </>
  );
}
