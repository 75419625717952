import React from "react";
import GalleryUpload from "../../editor/GalleryUpload/GalleryUpload";

const AddPhotoActionTool = ({
  setImages,
  setShowGalleryModal,
  setFiles,
  setIsProcessingImages,
}: any) => {
  function handleImages(uploadedImages: any[]) {
    const newImages = [...uploadedImages];
    setImages(newImages);
    setShowGalleryModal(true);
  }

  return (
    <>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="w-10 h-10 p-2 bg-white rounded-3xl shadow border border-gray-300 justify-center items-center inline-flex">
          <GalleryUpload
            setIsProcessingImages={setIsProcessingImages}
            setFiles={setFiles}
            processImages={handleImages}
          >
            <div className="text-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
            </div>
          </GalleryUpload>
        </div>
        <div className=" justify-start items-start gap-1 inline-flex">
          <div className="text-gray-600 text-sm font-medium font-['Inter'] leading-tight">
            Add Photo
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhotoActionTool;
