import { CheckCircleIcon } from "@heroicons/react/solid";
import React from "react";

interface ConnectionStatusBadgeProps {
  isConnected: boolean;
  size?: string;
}

export default function ConnectionStatusBadge({
  isConnected,
  size,
}: ConnectionStatusBadgeProps) {
  let customStyle = "";
  let textStyle = "text-body-medium";

  if (size === "sm") {
    customStyle = "p-2";
    textStyle = "text-sm-medium";
  }

  function renderStatus() {
    if (isConnected)
      return (
        <div
          className={`${
            size ? customStyle : "p-3"
          } ${textStyle} bg-green-100 text-green-800 rounded-full sm:px-4 flex items-center`}
        >
          <CheckCircleIcon className="flex w-6 h-6 text-green-800 lg:mr-2" />
          <p className="hidden lg:block">Connected</p>
        </div>
      );
    else
      return (
        <div
          className={`bg-red-100 text-red-800 rounded-full p-3 flex items-center`}
        >
          Disconnected
        </div>
      );
  }

  return <>{renderStatus()}</>;
}
