export const areTheseStringEqual = (
  string1: string,
  string2: string
): boolean => {
  return string1?.toLowerCase() === string2?.toLowerCase();
};

export const DAY_IN_MILLISECONDS = 86400000;

export const getDaysRemaining = (timestamp: number): number => {
  const currentTimestamp: number = Math.floor(Date.now() / 1000); // Current timestamp in seconds

  if (isNaN(timestamp) || timestamp <= currentTimestamp) {
    return 0; // Invalid or expired timestamp, return 0 days remaining
  }

  // Convert timestamps to milliseconds
  const currentDate: Date = new Date(currentTimestamp * 1000);
  const targetDate: Date = new Date(timestamp * 1000);

  // Calculate the difference in milliseconds
  const diffInMilliseconds: number = Math.abs(
    targetDate.getTime() - currentDate.getTime()
  );

  // Convert milliseconds to days
  const millisecondsInADay: number = 1000 * 60 * 60 * 24;
  const diffInDays: number = Math.ceil(diffInMilliseconds / millisecondsInADay);

  return diffInDays;
};
