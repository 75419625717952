import React from "react";
import GalleryAsset from "../../../common/GalleryAsset/GalleryAsset";
import { Image } from "../EditorPage/EditorPage";
import useIsMobile from "../../../../hooks/useIsMobile";

export interface GalleryImageProps {
  image: Image;
  className?: string;
}

export default function GalleryImage({ image, className }: GalleryImageProps) {
  const isMobile = useIsMobile();

  return (
    <div
      style={{ width: isMobile ? "100%" : 148, height: isMobile ? 96 : 118 }}
      className="relative inline-block sm:mr-2 sm:mb-2"
    >
      <GalleryAsset media={image} className={className} />
    </div>
  );
}
