import { useContext, useEffect, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  addHttp,
  renderCopyButton,
  renderDomainLink,
  saveConnectionsScreenShownDb,
} from "../DashboardPage/utils";
import ActionModal from "../../../common/ActionModal/ActionModal";
import ConnectionModal from "../../../common/ConnectionModal/ConnectionModal";
import { useAuthInstagram } from "../../../../hooks/useAuthInstagram";
import DashboardConnections from "../DashboardConnections/DashboardConnections";

interface ConnectionsScreenProps {
  showConnectionsScreen: any;
  setShowConnectionsScreen: (arg0: boolean) => void;
}

export default function ConnectionsScreen({
  setShowConnectionsScreen,
}: ConnectionsScreenProps) {
  const {
    profileInfo,
    isGmbAuthenticated,
    isFacebookAuthenticated,
    isQuickbooksAuthenticated,
    doesUseQuickbooks,
    googleContactsAuthenticated,
    isInstagramAuthenticated,
  } = useContext(DesktopContext);

  const [isExitModalOpen, setIsExitModalOpen] = useState<boolean>(false);
  const [celebrationModalOpen, setCelebrationModalOpen] =
    useState<boolean>(false);

  const { domain } = profileInfo || {};

  useEffect(() => {
    if (howManyConnected() === 4) {
      setCelebrationModalOpen(true);

      setTimeout(() => {
        setCelebrationModalOpen(false);
        setShowConnectionsScreen(false);
        saveConnectionsScreenShownDb();
      }, 5000);
    }
  }, [
    isFacebookAuthenticated,
    isGmbAuthenticated,
    isQuickbooksAuthenticated,
    doesUseQuickbooks,
    googleContactsAuthenticated,
  ]);

  function howManyConnected() {
    const connectionsArray = [
      isFacebookAuthenticated,
      isGmbAuthenticated,
      isInstagramAuthenticated,
      isQuickbooksAuthenticated || doesUseQuickbooks === "no",
      googleContactsAuthenticated,
    ];
    const count = connectionsArray.filter(Boolean).length;
    return count;
  }

  const { renderInstagramErrorConnectionModal } = useAuthInstagram();

  function startExitConnectionsScreen() {
    setIsExitModalOpen(true);
  }

  function confirmExitConnectionsScreen() {
    setIsExitModalOpen(false);
    setShowConnectionsScreen(false);
    saveConnectionsScreenShownDb();
  }

  const domainLink = addHttp(domain);

  return (
    <>
      <ConnectionModal
        isOpen={celebrationModalOpen}
        setIsOpen={setCelebrationModalOpen}
        celebrate={true}
        title="You're all set up"
      />
      <ActionModal
        header="Are you sure?"
        subheader="Connecting Facebook, Google My Business, Quickbooks, and Instagram will help you rank higher in search results."
        open={isExitModalOpen}
        setOpen={setIsExitModalOpen}
        secondaryButtonFunction={() => setIsExitModalOpen(false)}
        primaryButtonFunction={confirmExitConnectionsScreen}
        primaryButtonColor="red"
        primaryButtonText="Skip"
      />
      <div className="flex flex-col justify-start w-full pt-4 pb-8 px-4 sm:px-8 sm:space-y-0 bg-gray-50 min-h-screen">
        <div className="flex sm:flex-row flex-col justify-between">
          <h1 className={`text-h1-semibold text-gray-900 mt-8`}>
            {profileInfo?.company_name
              ? profileInfo?.company_name
              : "Dashboard"}
          </h1>
          {!!domain && (
            <div className="flex flex-row items-center space-x-3 py-2">
              {renderDomainLink(domainLink, domain)}
              {renderCopyButton(domain)}
            </div>
          )}
        </div>
        <div className="sm:max-w-3xl">
          <h1 className="text-h1-bold text-gray-900 pt-3 pb-6">
            Welcome to Topline Pro
          </h1>
          <div className="text-gray-600">
            Connect your Facebook, Google, and Quickbooks accounts to get
            started
          </div>
          <DashboardConnections />
          <div className="flex justify-center text-sm-medium text-blue-600 pt-6 pb-16">
            <div
              className="w-auto cursor-pointer"
              data-cy="set-up-later"
              onClick={startExitConnectionsScreen}
            >
              Set up later
            </div>
          </div>
        </div>
      </div>

      {renderInstagramErrorConnectionModal()}
    </>
  );
}
