import React from "react";
interface SocialMediaStatsProps {
  followerCount: number;
  numberOfPosts: number;
  totalLikes: number;
}

export default function SocialMediaStats({
  followerCount,
  numberOfPosts,
  totalLikes,
}: SocialMediaStatsProps) {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col items-center mr-6 sm:mr-8">
        <h3 className="text-h3-bold text-gray-900">{followerCount}</h3>
        <div className="text-sm-normal text-gray-600">Followers</div>
      </div>
      <div className="flex flex-col items-center mr-6 sm:mr-8">
        <h3 className="text-h3-bold text-gray-900">{numberOfPosts}</h3>
        <div className="text-sm-normal text-gray-600">Posts</div>
      </div>
      <div className="flex flex-col items-center">
        <h3 className="text-h3-bold text-gray-900">{totalLikes}</h3>
        <div className="text-sm-normal text-gray-600">Likes</div>
      </div>
    </div>
  );
}
