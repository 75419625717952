const WidgetHeader: React.FC = ({ children }) => {
  return (
    <>
      <div className="flex sm:flex-row flex-col justify-between items-start sm:items-center w-full">
        {children}
      </div>
    </>
  );
};

export default WidgetHeader;
