import React from "react";

interface WidgetContainerProps {
  children: React.ReactNode;
  centeredContent?: boolean;
  customStyle?: string;
  dataCy?: string;
}

export default function WidgetContainer({
  children,
  customStyle,
  dataCy,
}: WidgetContainerProps) {
  return (
    <div
      className={`flex flex-col bg-white rounded-lg p-6 w-full mt-4 shadow ${customStyle}`}
      data-cy={dataCy ? dataCy : ""}
    >
      {children}
    </div>
  );
}
