import React, { ReactElement, useContext, useEffect, useState } from "react";
import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import useIsMobile from "../../../../hooks/useIsMobile";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import Link from "next/link";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import NewPost from "../../editor/NewPost/NewPost";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { findFbGooglePosts } from "../../editor/utils";
import SocialMediaStats from "../SocialMediaStats/SocialMediaStats";
import SocialMediaTile from "../SocialMediaTile/SocialMediaTile";
import { IFacebookPageData, ISocialMediaPost } from "../DashboardPage/types";
import { useRouter } from "next/router";
import { useNewPostModal } from "../../../../hooks/useNewPostModal/useNewPostModal";
import { fetchNewDigitalMedia } from "../../editor/PostsPage/utils";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

export interface SocialMediaWidgetProps {
  scheduledPosts: ISocialMediaPost[];
  allPosts: ISocialMediaPost[];
  facebookPageData: IFacebookPageData;
  createPostModalOpen: boolean;
  setCreatePostModalOpen: (_arg0: boolean) => void;
}

export default function SocialMediaWidget({
  scheduledPosts,
  allPosts,
  facebookPageData,
  createPostModalOpen,
  setCreatePostModalOpen,
}: SocialMediaWidgetProps): ReactElement {
  const { followerCount, totalLikes } = facebookPageData;
  const { renderChurnModal } = useChurnModal();
  const isMobile = useIsMobile();
  const router = useRouter();
  const {
    profileInfo,
    isFacebookAuthenticated,
    isInstagramAuthenticated,
    savedPost,
    setSavedPost,
    allDigitalMedia,
    basicInfo,
  } = useContext(DesktopContext);

  const slug = profileInfo?.slug;
  const footer = basicInfo?.social_post_footer;

  const {
    renderCreateAPostModal,
    handleImagesSelected,
    handleGeneratePost,
    newMessage,
    setNewMessage,
    attachedPhotos,
    setAttachedPhotos,
    newMediaLoading,
    setNewMediaLoading,
    setAttachingPhoto,
    setGeneratingPost,
    setReplaceMessageModalOpen,
  } = useNewPostModal();

  const [rewritePostErrorMessage, setRewritePostErrorMessage] = useState("");
  const [displayedScheduledPosts, setDisplayedScheduledPosts] = useState<any[]>(
    []
  );
  const [displayedLivePosts, setDisplayedLivePosts] = useState<any[]>([]);

  useEffect(() => {
    if (!!scheduledPosts && !!allPosts) {
      const scheduledPostsNoDupes = findFbGooglePosts(scheduledPosts);

      if (scheduledPostsNoDupes && scheduledPostsNoDupes.length > 1) {
        const firstTwoScheduledPosts = scheduledPostsNoDupes?.slice(0, 2) || [];
        setDisplayedScheduledPosts(firstTwoScheduledPosts);
        setDisplayedLivePosts([]);
      } else if (scheduledPostsNoDupes && scheduledPostsNoDupes.length === 1) {
        const scheduledPost = [scheduledPostsNoDupes[0]];
        const latestPost = allPosts?.length > 0 ? [allPosts[0]] : [];
        setDisplayedScheduledPosts(scheduledPost);
        setDisplayedLivePosts(latestPost);
      } else {
        const twoLatestPosts = allPosts?.slice(0, 2) || [];
        setDisplayedScheduledPosts([]);
        setDisplayedLivePosts(twoLatestPosts);
      }
    }
  }, [scheduledPosts, allPosts]);

  useEffect(() => {
    if (newMessage && rewritePostErrorMessage) {
      setRewritePostErrorMessage("");
    }
  }, [newMessage]);

  useEffect(() => {
    if (router?.query?.postModalOpen === "true") {
      setCreatePostModalOpen(true);
      setNewMessage(savedPost?.text || "");
      setAttachedPhotos(savedPost?.photos || []);
      setSavedPost({});
    }
    if (router?.query?.postModalOpen) {
      router.replace(router.pathname);
    }
  }, []);

  useEffect(() => {
    if (router?.query?.fromDigitalMedia) {
      const savedText = savedPost?.text;
      if (!savedText || savedText?.trim() === footer) {
        setGeneratingPost(true);
      }

      setNewMediaLoading(true);
      setCreatePostModalOpen(true);
      setAttachingPhoto(true);
      router.replace(router.pathname);
    }
  }, []);

  useEffect(() => {
    if (newMediaLoading) {
      fetchNewDigitalMedia(
        slug,
        setNewMediaLoading,
        setAttachingPhoto,
        attachedPhotos,
        setAttachedPhotos,
        handleGeneratePost,
        newMessage,
        footer,
        setReplaceMessageModalOpen
      );
    }
  }, [allDigitalMedia]);

  return (
    <>
      <UnsubscribedEmptyStateWrapper>
        <WidgetContainer>
          <div className="flex flex-row justify-between items-center pb-1">
            <h1 className="text-h2-bold text-gray-900">Social Media</h1>
            {isFacebookAuthenticated && (
              <SocialMediaStats
                followerCount={followerCount}
                numberOfPosts={allPosts?.length}
                totalLikes={totalLikes}
              />
            )}
          </div>

          {renderCreateAPostModal(createPostModalOpen, setCreatePostModalOpen)}

          <NewPost
            handleImagesSelected={handleImagesSelected}
            createPostModalOpen={createPostModalOpen}
            setCreatePostModalOpen={setCreatePostModalOpen}
            location="dashboard"
            handleGeneratePost={handleGeneratePost}
            newMessage={newMessage}
            attachedPhotos={attachedPhotos}
          />

          <div>
            {displayedScheduledPosts.length > 0 && (
              <h2 className="text-h3-semibold mt-4">Scheduled Posts</h2>
            )}
            <div>
              {displayedScheduledPosts.length > 0 &&
                displayedScheduledPosts?.map((post, index) => {
                  const { created_time, origin, message, full_picture } = post;
                  let photoUrl = full_picture;
                  if (full_picture) {
                    try {
                      const photoLinkDoubleQuoted = full_picture.replace(
                        /'/g,
                        '"'
                      );
                      const link = JSON.parse(photoLinkDoubleQuoted);
                      photoUrl = link[0];
                    } catch (e) {
                      photoUrl = null;
                    }
                  } else {
                    photoUrl = null;
                  }

                  return (
                    <SocialMediaTile
                      key={post.id}
                      post={post}
                      index={index}
                      message={message}
                      origin={origin}
                      created_time={created_time}
                      photoUrl={photoUrl}
                      section="scheduled-posts"
                      totalPostsInSection={displayedScheduledPosts?.length}
                    />
                  );
                })}
            </div>

            {displayedLivePosts.length > 0 && (
              <h2 className="text-h3-semibold mt-4">Recent Posts</h2>
            )}
            <div>
              {displayedLivePosts.length > 0 &&
                displayedLivePosts?.map((post, index) => {
                  const { created_time, origin, message, full_picture } = post;
                  const photoUrl = full_picture;
                  return (
                    <SocialMediaTile
                      key={post.id}
                      post={post}
                      index={index}
                      message={message}
                      origin={origin}
                      created_time={created_time}
                      photoUrl={photoUrl}
                      section="recent-posts"
                      totalPostsInSection={displayedLivePosts?.length}
                    />
                  );
                })}
            </div>
          </div>

          <div className="flex justify-center">
            <Link href="/social">
              <a>
                <PrimaryLink
                  size={isMobile ? "sm" : "md"}
                  text={`View all posts`}
                  icon="right-arrow"
                  iconPlacement="lagging"
                />
              </a>
            </Link>
          </div>
        </WidgetContainer>
      </UnsubscribedEmptyStateWrapper>
    </>
  );
}
