import React, { ReactElement, ReactNode, useContext, useEffect } from "react";
import { getLayout as getSiteLayout } from "../layouts/SiteLayout/SiteLayout";
import DashboardPage from "../components/primaryrender/dashboard/DashboardPage/DashboardPage";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import ShimmerLoadingViewDashboard from "../components/uiwrappers/ShimmerLoadingViewDashboard/ShimmerLoadingViewDashboard";
import { getUserHashIntercom } from "../components/primaryrender/dashboard/DashboardPage/utils";

function Home(): ReactElement {
  const {
    profileInfo,
    reviewInfo,
    userSchedulePosts,
    allUserStripeInvoices,
    allRequests,
    initialApiLoadingState,
    competitorsLoading,
    mutateProfileInfo,
  } = useContext(DesktopContext);

  useEffect(() => {
    async function bootIntercom() {
      let intercom_user_hash;
      if (profileInfo) {
        const response = await getUserHashIntercom();
        intercom_user_hash = response?.data?.message;

        if (window.Intercom) {
          const intercom_app_id = `${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}`;
          window.Intercom("boot", {
            app_id: intercom_app_id,
            name: profileInfo?.first_name,
            email: profileInfo?.email,
            user_id: profileInfo?.customer_pk,
            user_hash: intercom_user_hash,
            hide_default_launcher: true,
            custom_launcher_selector: "#intercom-launcher",
          });
        }
      }
    }
    mutateProfileInfo();
    bootIntercom();
  }, []);

  const { isStripeInfoLoading, isDjangoProfileLoading } =
    initialApiLoadingState;

  if (
    profileInfo &&
    !profileInfo.slug &&
    !isStripeInfoLoading &&
    !isDjangoProfileLoading
  ) {
    return <>{profileInfo && <DashboardPage />}</>;
  }

  if (
    isStripeInfoLoading ||
    isDjangoProfileLoading ||
    !profileInfo ||
    !reviewInfo ||
    !userSchedulePosts ||
    (!allUserStripeInvoices && !!profileInfo?.stripe_connect_account_id) ||
    !allRequests ||
    competitorsLoading
  ) {
    return <ShimmerLoadingViewDashboard />;
  }
  return <>{profileInfo && <DashboardPage />}</>;
}

Home.getLayout = (page: ReactNode) => getSiteLayout(page);

export default Home;
