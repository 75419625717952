import React, { useContext } from "react";
import AddPhotoActionTool from "../AddPhotoActionTool/AddPhotoActionTool";
import ReviewDrawer from "../../../ReviewDrawer/ReviewDrawer";
import { PencilAltIcon, QrcodeIcon } from "@heroicons/react/outline";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";

const QuickActionTools = ({
  setCreatePostModalOpen,
  setShowGalleryModal,
  setImages,
  setFiles,
  isProcessingImages,
  setIsProcessingImages,
}: any) => {
  const { setShowQRReviewDrawer } = useContext(DesktopContext);

  return (
    <>
      <div className=" h-20 w-full px-4 py-2.5  justify-start items-center gap-2.5 inline-flex">
        <div className="w-full self-stretch justify-center items-center gap-8 flex">
          <AddPhotoActionTool
            setImages={setImages}
            setShowGalleryModal={setShowGalleryModal}
            setFiles={setFiles}
            isProcessingImages={isProcessingImages}
            setIsProcessingImages={setIsProcessingImages}
          />
          <div
            className="flex-col justify-center items-center gap-1 inline-flex"
            onClick={() => {
              setShowQRReviewDrawer(true);
            }}
          >
            <div className="w-10 h-10 p-2 bg-white rounded-3xl shadow border border-gray-300 justify-center items-center inline-flex">
              <QrcodeIcon className="w-6 h-6 text-blue-600" />
            </div>
            <div className=" justify-start items-start gap-1 inline-flex">
              <div className="text-gray-600 text-sm font-medium font-['Inter'] leading-tight">
                Review Link
              </div>
            </div>
          </div>
          <div
            className="flex-col justify-center items-center gap-1 inline-flex"
            onClick={() => setCreatePostModalOpen(true)}
          >
            <div className="w-10 h-10 p-2 bg-white rounded-3xl shadow border border-gray-300 justify-center items-center inline-flex">
              <PencilAltIcon className="w-6 h-6 text-blue-600" />
            </div>
            <div className=" justify-start items-start gap-1 inline-flex">
              <div className="text-gray-600 text-sm font-medium font-['Inter'] leading-tight">
                New Post
              </div>
            </div>
          </div>
        </div>
        <ReviewDrawer />
      </div>
    </>
  );
};

export default QuickActionTools;
