import {
  ArrowRightIcon,
  DocumentAddIcon,
  SparklesIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Combobox } from "@headlessui/react";
import { AssistantAction, ContactActions } from "./types";

import {
  AssistantService,
  classNames,
  handleFuseSearch,
  hotkey,
} from "./utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { MicrophoneIcon, XIcon } from "@heroicons/react/solid";
import DynamicHeroIcon from "../DynamicIcon/DynamicIcon";
import useIsMobile from "../../hooks/useIsMobile";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { debounce } from "lodash";
import { IContact } from "../primaryrender/contacts/Contact/types";

const Assistant: React.FC = () => {
  const [prompt, setPrompt] = useState<string>("");
  const [focus, setFocus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();

  const [actions, setActions] = useState<AssistantAction[]>([]);
  const [defaultActions, setDefaultActions] = useState<AssistantAction[]>([]);
  const [filteredActionList, setFilteredActionList] = useState<
    AssistantAction[]
  >([]);
  const [showAiPoweredSearch, setShowAiPoweredSearch] = useState<boolean>(true);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [contactActions, setContactActions] = useState<ContactActions>({
    viewContact: [] as AssistantAction[],
    paymentRequest: [] as AssistantAction[],
    reviewRequest: [] as AssistantAction[],
  });

  const { transcript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const isMobile = useIsMobile();

  const { contacts, setChatbotOpen } = useContext(DesktopContext);

  const debouncedFuseSearch = useRef(
    debounce((actions, contactActions, prompt) => {
      const newActionList = handleFuseSearch(actions, contactActions, prompt);
      setFilteredActionList(newActionList);
      setLoading(false);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedFuseSearch.cancel();
    };
  }, [debouncedFuseSearch]);

  const semanticSearch = async (prompt: string, limit: number) => {
    setLoading(true);
    setShowAiPoweredSearch(false);

    AssistantService.assistantSearch(prompt, limit)
      .then((result) => {
        setFilteredActionList(result);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    AssistantService.getAllActions().then((data) => {
      setActions(data);
      const defaultActions = data.filter(
        (action: AssistantAction) => action.default_icon
      );
      setDefaultActions(defaultActions);
    });
  }, []);

  useEffect(() => {
    if (contacts && contacts.length) {
      setContactActions(createContactActions(contacts));
    }
  }, [contacts]);

  useEffect(() => {
    if (focus && prompt) {
      setLoading(true);
      setDebouncedSearch(prompt);
    } else {
      debouncedFuseSearch.cancel();
      setFilteredActionList([]);
      setLoading(false);
      setPrompt("");
    }
  }, [prompt, focus]);

  useEffect(() => {
    if (debouncedSearch) {
      debouncedFuseSearch(actions, contactActions, debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    const focus = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "k") {
        setChatbotOpen(true);
      }
    };
    window.addEventListener("keydown", focus);
    return () => {
      window.removeEventListener("keydown", focus);
    };
  });

  useEffect(() => {
    const blur = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        inputRef.current?.blur();
        setFocus(false);
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", blur);
    return () => {
      window.removeEventListener("keydown", blur);
    };
  });

  useEffect(() => {
    setPrompt(transcript);
  }, [transcript]);

  function getSuperAssistantWidth() {
    if (isMobile && focus) {
      return "calc(100% - 32px)";
    } else if (!isMobile && focus) {
      return `500px`;
    }
  }

  const createContactActions = (contacts: IContact[]) => {
    const contactActionsResult: ContactActions = {
      viewContact: [] as AssistantAction[],
      paymentRequest: [] as AssistantAction[],
      reviewRequest: [] as AssistantAction[],
    } as ContactActions;
    contacts.forEach((c: IContact) => {
      contactActionsResult.viewContact.push({
        id: `contact_${c.id}`,
        title: `View contact for ${c.first_name} ${c.last_name}`,
        frontend_url: `/contacts?viewContact=${c.id}`,
        default_icon: null,
        is_ai: false,
        contact: true,
      } as AssistantAction);
      contactActionsResult.reviewRequest.push({
        id: `review_${c.id}`,
        title: `Send a review request to ${c.first_name} ${c.last_name}`,
        frontend_url: `/reviews?reviewRecipient=${c.id}`,
        default_icon: null,
        is_ai: false,
        contact: true,
      } as AssistantAction);
      contactActionsResult.paymentRequest.push({
        id: `payment_${c.id}`,
        title: `Send a payment request to ${c.first_name} ${c.last_name}`,
        frontend_url: `/payments?paymentRecipient=${c.id}`,
        default_icon: null,
        is_ai: false,
        contact: true,
      } as AssistantAction);
    });
    return contactActionsResult;
  };

  const handleActionSelect = async (action: AssistantAction) => {
    if (action) {
      inputRef.current?.blur();
      setFocus(false);
      if (!action.contact && typeof action.id === "number") {
        await AssistantService.increaseActionCount(action.id);
      }
      if (action.frontend_url.includes("?focusHelpAndSupport=true")) {
        if (isMobile) {
          router.push("/settings?focusHelpAndSupport=true");
        } else {
          router.push("/home?focusHelpAndSupport=true");
        }
      } else {
        if (
          action.title === "Send a review request" &&
          contacts &&
          contacts.length
        ) {
          const lowerPromptArray = prompt.toLowerCase().split(" ");
          const foundContact = contacts.find((contact: IContact) => {
            return (
              lowerPromptArray.includes(contact.first_name.toLowerCase()) &&
              lowerPromptArray.includes(contact.last_name.toLowerCase())
            );
          });
          if (foundContact) {
            return router.push(`/reviews?reviewRecipient=${foundContact.id}`);
          }
        }
        router.push(action.frontend_url);
      }
    } else {
      semanticSearch(prompt, 6);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
    setShowAiPoweredSearch(true);
  };

  function blurFocus() {
    setTimeout(() => inputRef?.current?.blur(), 0);
  }

  return (
    <div className="w-full my-4 sm:my-0 sm:mr-3 bg-white sm:bg-gray-50 mr-0">
      <div
        className={
          focus
            ? "fixed z-10 inset-0 w-full h-full bg-gray-500 bg-opacity-50 backdrop-filter backdrop-blur-sm"
            : ""
        }
        onClick={() => {
          setFocus(false);
        }}
      />
      <Combobox as={Fragment} onChange={handleActionSelect}>
        <div className="relative">
          <div
            onClick={() => setChatbotOpen(true)}
            className={
              "border border-gray-400 rounded min-w-300 sm:w-full bg-white sm:min-w-300 sm:max-w-500" +
              (focus ? " fixed z-20" : "")
            }
            style={{ width: getSuperAssistantWidth() }}
          >
            <div className="px-4">
              <div className="flex items-center gap-1.5">
                <label htmlFor="assistant-search">
                  <SparklesIcon
                    className="w-4 h-4 text-purple-400"
                    aria-hidden="true"
                  />
                </label>
                <Combobox.Input
                  id="assistant-search"
                  className={
                    "appearance-none outline-none flex-1 text-base sm:text-sm py-3 sm:py-3.5 px-0 border-0 focus:outline-none focus:ring-0 focus:placeholder-gray-400"
                  }
                  value={prompt}
                  onChange={handleChange}
                  placeholder={
                    focus
                      ? "Type a command or ask anything..."
                      : "Try 'Generate a blog', 'Respond to a review'..."
                  }
                  ref={inputRef}
                />
                {!focus && (
                  <p className="px-2.5 py-0.5 rounded-full text-xs shadow hidden sm:block">
                    {hotkey()}
                  </p>
                )}
                {prompt && (
                  <XIcon
                    aria-hidden="true"
                    className={classNames(
                      "w-4 h-4 mr-2",
                      "text-gray-400",
                      "cursor-pointer"
                    )}
                    onClick={() => setPrompt("")}
                  />
                )}
                {browserSupportsSpeechRecognition && (
                  <div>
                    <MicrophoneIcon
                      className={`w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-900 ${
                        listening ? "text-red-500" : "text-gray-500"
                      }`}
                      onClick={() => {
                        SpeechRecognition.startListening();
                        setFocus(true);
                      }}
                    />
                  </div>
                )}
                {!prompt && !focus && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-gray-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                )}
              </div>
            </div>

            {loading && (
              <div className="flex justify-center items-center px-6 py-14 text-center sm:px-14">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 mr-2 text-gray-200 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                      className="text-blue-600"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {prompt === "" && focus && filteredActionList.length === 0 && (
              <div>
                <Combobox.Options
                  static
                  className="max-h-500 overflow-y-auto flex-col gap-1.5 px-2 py-2 text-sm text-gray-700 border-t"
                >
                  <div className="text-xs-semibold px-3 py-2">
                    Popular commands
                  </div>
                  {defaultActions.map((option: AssistantAction) => (
                    <Combobox.Option
                      key={option.id}
                      value={option}
                      className={({ active }) =>
                        classNames(
                          "text-sm px-3 py-2 rounded cursor-pointer flex gap-2 items-center group",
                          active && "bg-gray-700 text-white"
                        )
                      }
                      onClick={() => {
                        setFocus(false);
                        blurFocus();
                      }}
                    >
                      {({ active }) => (
                        <>
                          {option?.default_icon && (
                            <DynamicHeroIcon
                              icon={option.default_icon}
                              customStyle={classNames(
                                "w-5 h-5",
                                active ? "text-white" : "text-gray-500"
                              )}
                            />
                          )}
                          <span>{option.title}</span>
                          {option.is_ai && (
                            <>
                              <DocumentAddIcon
                                className={classNames(
                                  "w-4 h-4 ml-auto",
                                  active ? "text-white" : "text-gray-400"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                          {!option.is_ai && (
                            <ArrowRightIcon
                              aria-hidden="true"
                              className={classNames(
                                "w-4 h-4 ml-auto",
                                active ? "text-white" : "text-gray-400"
                              )}
                            />
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            )}

            {/* Result ul */}
            {!loading && focus && prompt && (
              <Combobox.Options
                static
                className="max-h-500 overflow-y-auto p-2 border-t"
              >
                {showAiPoweredSearch && (
                  <Combobox.Option
                    value=""
                    className={({ active }) =>
                      classNames(
                        "text-sm px-3 py-2 rounded cursor-pointer flex gap-2 items-center group",
                        active && "bg-gray-700 text-white"
                      )
                    }
                    onClick={() => semanticSearch(prompt, 6)}
                  >
                    {({ active }) => (
                      <>
                        <span
                          className={
                            !active
                              ? "text-purple-500 text-xs-semibold"
                              : "bg-gray-700 text-white text-xs-semibold"
                          }
                        >
                          AI-powered search{" "}
                          <span
                            className={
                              !active
                                ? "text-gray-500 text-sm-normal"
                                : "text-white text-sm-normal"
                            }
                          >
                            '{prompt}'
                          </span>
                        </span>

                        <SearchIcon
                          aria-hidden="true"
                          className={classNames(
                            "w-4 h-4 ml-auto",
                            active ? "text-white" : "text-gray-400"
                          )}
                        />
                      </>
                    )}
                  </Combobox.Option>
                )}

                {filteredActionList.length > 0 && (
                  <div className="text-xs-semibold px-3 py-2">Command</div>
                )}

                {filteredActionList.map((action: AssistantAction) => (
                  <Combobox.Option
                    key={action.id}
                    value={action}
                    className={({ active }) =>
                      classNames(
                        "text-sm px-3 py-2 rounded cursor-pointer flex gap-2 items-center group",
                        active && "bg-gray-700 text-white"
                      )
                    }
                    onClick={() => {
                      setFocus(false);
                      blurFocus();
                    }}
                  >
                    {({ active }) => (
                      <>
                        <span>{action.title}</span>
                        {action.is_ai && (
                          <>
                            <SparklesIcon
                              className={classNames(
                                "w-4 h-4",
                                active ? "text-white" : "text-purple-400"
                              )}
                              aria-hidden="true"
                            />
                            <DocumentAddIcon
                              className={classNames(
                                "w-4 h-4 ml-auto",
                                active ? "text-white" : "text-gray-400"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                        {!action.is_ai && (
                          <ArrowRightIcon
                            aria-hidden="true"
                            className={classNames(
                              "w-4 h-4 ml-auto",
                              active ? "text-white" : "text-gray-400"
                            )}
                          />
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>

          {/* Placeholder div */}
          {focus && (
            <div className="h-12 border border-transparent box-content"></div>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export default Assistant;
