import React, { ReactElement } from "react";

export default function ShimmerLoadingViewDashboard(): ReactElement {
  return (
    <div className="flex flex-col p-8 w-full">
      <div className="flex flex-col w-full">
        <div className="animate-pulse bg-gray-200 shadow rounded-md h-20 mb-8"></div>
      </div>
      <div className="sm:hidden flex flex-col w-full">
        <div className="animate-pulse bg-gray-200 shadow rounded-md h-20 mb-4"></div>
        <div className="animate-pulse bg-gray-200 shadow rounded-md h-96 mb-8"></div>
      </div>
      <div className="hidden sm:flex flex-row">
        <div className="flex flex-col w-1/2 mr-8">
          <div className="animate-pulse bg-gray-200 shadow rounded-md h-full"></div>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="animate-pulse bg-gray-200 shadow rounded-md h-40 mb-8"></div>
          <div className="animate-pulse bg-gray-200 shadow rounded-md h-60 mb-8"></div>
          <div className="animate-pulse bg-gray-200 shadow rounded-md h-60"></div>
        </div>
      </div>
    </div>
  );
}
