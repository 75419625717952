import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useMemo } from "react";
import moment from "moment";

const ReChartBarChart = ({ data }: { data: Map<Date, number> }) => {
  const convertedData = useMemo(() => {
    const tempArray: { time: Date; appearances: number }[] = [];
    data.forEach((value, key) => {
      tempArray.push({ time: key, appearances: value });
    });
    return tempArray;
  }, [data]);
  return (
    <div className="w-full h-full md:block hidden">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          // width={500}
          // height={300}
          data={convertedData}
          margin={{
            right: 0,
            left: -10,
            bottom: -10,
            top: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey="time"
            interval={Math.floor(convertedData.length / 4)}
            tick={{ fontSize: 12, fill: "#9CA3AF" }}
            tickFormatter={(timeStr) => moment(timeStr).format("MMM DD")}
            type="category"
            tickLine={false}
            stroke={"#D1D5DB"}
          />
          <YAxis
            type="number"
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12, fill: "#9CA3AF" }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="appearances" fill="#2563eb" radius={[15, 15, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReChartBarChart;
