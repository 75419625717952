/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowCircleUpIcon,
  CheckIcon,
  RefreshIcon,
  ThumbUpIcon,
  XIcon,
} from "@heroicons/react/solid";
import React, { Fragment, useContext, useState } from "react";
import { useAuthFacebook } from "../../../../hooks/useAuthFacebook";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import { postFacebookMessage } from "../../editor/utils";
import { updateShowLiveWebsiteFacebookPopUp } from "../DashboardPage/utils";
import { facebookToast, postToast } from "../../../../hooks/utils";

export interface WelcomePopupProps {
  slug: string;
  domain: string;
  facebookPageToken: string;
  facebookPageId: string;
  setShowFacebookPopup: any;
  step: number;
  setStep: any;
}

export default function WelcomePopup({
  slug,
  domain,
  facebookPageToken,
  facebookPageId,
  step,
  setStep,
}: WelcomePopupProps) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [facebookPost, setFacebookPost] = useState<string>(
    `Excited to share our new website at ${domain}.\n\nWe're showcasing some of our best work, our services, and our customer reviews. Plus it's easy to reach out to us from our site. Check it out!`
  );
  const [justConnected, setJustConnected] = useState<boolean>(false);
  const {
    connectToFacebook,
    renderFacebookButton,
    renderFacebookMultiPageModal,
  } = useAuthFacebook(slug);

  const { profileInfo, setProfileInfo } = useContext(DesktopContext);

  function handleOnChange(e) {
    setFacebookPost(e.target.value);
  }

  async function makePost() {
    setLoading(true);
    setDisabled(true);
    try {
      const fbPostResponse = await postFacebookMessage(
        facebookPost,
        facebookPageId,
        facebookPageToken
      );
      try {
        if (fbPostResponse.error) {
          postToast("failure");
        } else {
          postToast("success");
        }
      } catch (e) {
        console.log("no error on response");
      }
    } catch (error) {
      console.log("error", error);
      postToast("failure");
    }
    setLoading(false);
    setDisabled(false);
    setOpen(false);
  }

  async function handleNotShowAgain() {
    await updateShowLiveWebsiteFacebookPopUp(slug);
    if (profileInfo)
      setProfileInfo({
        ...profileInfo,
        show_facebook_popup_for_live_site_announcement: false,
      });
    setOpen(false);
  }

  async function handleShare() {
    await updateShowLiveWebsiteFacebookPopUp(slug);
    makePost();
    if (profileInfo)
      setProfileInfo({
        ...profileInfo,
        show_facebook_popup_for_live_site_announcement: false,
      });
  }

  function renderFbButton() {
    const callbackFunction = async (response) => {
      const status = await connectToFacebook(response);
      if (status === "success") {
        setStep(3);
        setJustConnected(true);
      }
      facebookToast(status);
    };
    const customButton = (renderProps) => (
      <PrimaryButton
        text="Connect"
        size="md"
        onClickFunc={renderProps.onClick}
        customStyle="w-1/2"
      />
    );
    return renderFacebookButton(callbackFunction, customButton);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:px-6 sm:pt-10 sm:pb-6 -mt-16`}
                >
                  <button
                    type="button"
                    className="absolute top-0 right-0 pt-4 pr-4 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {step === 1 && (
                    <>
                      <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-2">
                          <Dialog.Title
                            as="h3"
                            className="text-h3-semibold text-gray-900"
                            data-cy="congrats-live-site"
                          >
                            Congratulations, your site is live
                          </Dialog.Title>
                          <div className="text-body-normal text-gray-900">
                            Check it out
                          </div>
                          <div className="flex text-body-normal mt-4 text-blue-600 focus:ring-0 focus:outline-none">
                            <a
                              className="m-auto focus:ring-0 focus:outline-none"
                              href={`https://${domain}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <PrimaryLink
                                text={domain}
                                size="lg"
                                customStyle="focus:ring-0 focus:outline-none"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <PrimaryButton
                          id="next-popup"
                          size="md"
                          text="Next"
                          onClickFunc={() => setStep(2)}
                          customStyle="w-full"
                        />
                      </div>
                    </>
                  )}
                  {(step === 2 || step === 3) &&
                    ((facebookPageToken && facebookPageToken !== "") ||
                      justConnected) && (
                      <>
                        <div>
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <img src="facebook_logo.png" alt="Facebook Logo" />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-h3-semibold text-gray-900"
                            >
                              Share the exciting news on Facebook
                            </Dialog.Title>
                            <div className="text-body-normal text-gray-900">
                              Get attention and business by posting
                            </div>
                            <div className="mt-4">
                              <DynamicInput
                                name="facebook-message"
                                value={facebookPost}
                                handleChange={handleOnChange}
                                textarea
                                edit
                                rows={7}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row mt-4">
                          <SecondaryButton
                            id="remind-me-later"
                            size="md"
                            text="Remind Me Later"
                            onClickFunc={() => setOpen(false)}
                            customStyle="w-1/2 mr-2"
                          />
                          <PrimaryButton
                            size="md"
                            text="Share"
                            onClickFunc={handleShare}
                            customStyle="w-1/2"
                            loading={loading}
                            disabled={disabled}
                          />
                        </div>
                        <div className="flex justify-center">
                          <PrimaryLink
                            id={`do-not-show-again`}
                            text="Don't show again"
                            size="sm"
                            onClickFunc={handleNotShowAgain}
                            customStyle="sm:mt-6 mt-2 text-center"
                          />
                        </div>
                      </>
                    )}
                  {step === 2 && !facebookPageToken && (
                    <>
                      <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                          <img src="facebook_logo.png" alt="Facebook Logo" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-h3-semibold text-gray-900"
                          >
                            Connect to Facebook
                          </Dialog.Title>
                          <div className="text-body-normal text-gray-900">
                            Share your news with your network
                          </div>
                          <div className="flex flex-col space-y-4 w-64 justify-center my-8 mb-12 m-auto">
                            <div className="flex flex-row">
                              <ThumbUpIcon className="w-6 h-6 text-blue-900 mr-3" />
                              <div className="text-gray-500">
                                Reach more people
                              </div>
                            </div>
                            <div className="flex flex-row">
                              <ArrowCircleUpIcon className="w-6 h-6 text-blue-900 mr-3" />
                              <div className="text-gray-500">
                                Rank higher in search results
                              </div>
                            </div>
                            <div className="flex flex-row">
                              <RefreshIcon className="w-6 h-6 text-blue-900 mr-3" />
                              <div className="text-gray-500">
                                Keep your website updated
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <SecondaryButton
                          size="md"
                          text="Connect Later"
                          onClickFunc={() => setOpen(false)}
                          customStyle="w-1/2 mr-2"
                        />

                        {renderFbButton()}
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          {renderFacebookMultiPageModal()}
        </Dialog>
      </Transition.Root>
    </>
  );
}
