import React, { useEffect, useState } from "react";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import GalleryImage from "../GalleryImage/GalleryImage";
import GalleryUpload from "../GalleryUpload/GalleryUpload";
import GenericDropdown from "../../../common/GenericDropdown/GenericDropdown";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PhotographIcon } from "@heroicons/react/outline";
import { useSyncSocials } from "../../../../hooks/useSyncSocials/useSyncSocials";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";
import UploadProgressBar from "./UploadProgressBar";

const UploadPhotoModal = ({
  isOpen,
  setIsOpen,
  images,
  setImages,
  gallerySelected,
  galleries,
  addPhotos,
  isUploading,
  setIsUploading,
  setFiles,
  addGallery,
  uploadedCount,
}: any) => {
  const [gallerySelectedInModal, setGallerySelectedInModal] =
    useState(gallerySelected);
  const [uploadButtonDisabled, setUploadButtonDisabled] =
    useState<boolean>(true);
  const [galleriesForDropdown, setGalleriesForDropdown] = useState<any[]>([]);
  const [uploadingText, setUploadingText] = useState(
    `Uploading 1 of ${images.length}...`
  );
  const [progress, setProgress] = useState(0);
  const [isProcessingImages, setIsProcessingImages] = useState(false);

  useEffect(() => {
    if (isUploading) {
      const maxProgress = (uploadedCount / images.length) * 100 || 0;
      if (progress < maxProgress) {
        setProgress(maxProgress);
      }

      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 0.25;
          }
          return prevProgress;
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [uploadedCount, isUploading]);

  const isMobile = useIsMobile();

  const {
    toggleGoogleStatus,
    toggleFacebookStatus,
    renderSyncSwitches,
    renderSyncModals,
  } = useSyncSocials("photos");
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();

  useEffect(() => {
    setGallerySelectedInModal(gallerySelected);
  }, [isOpen]);

  useEffect(() => {
    const galleryNames = galleries.map(
      (gallery: any) => gallery["gallery-name"]
    );
    const dropdownItems: any[] = [
      {
        text: "Add New Gallery",
        icon: "plus",
        iconPlacement: "leading",
        onClick: () => {
          addGallery("upload");
        },
      },
    ];
    galleryNames.forEach((galleryName: string) => {
      dropdownItems.push({
        text: galleryName,
        onClick: () => {
          setGallerySelectedInModal(galleryName);
        },
      });
    });

    setGalleriesForDropdown(dropdownItems);
  }, [galleries]);

  useEffect(() => {
    if (images.length > 0) {
      setUploadButtonDisabled(false);
    } else {
      setUploadButtonDisabled(true);
    }
  }, [images, gallerySelectedInModal]);

  useEffect(() => {
    if (images?.length && uploadedCount < images.length) {
      setUploadingText(`Uploading ${uploadedCount + 1} of ${images.length}...`);
    } else {
      setUploadingText(`Uploading ${uploadedCount} of ${images.length}...`);
    }
  }, [uploadedCount, images]);

  function handleImages(uploadedImages: any[]) {
    const newImages = [...uploadedImages];
    setImages(newImages);
  }

  function handleOnCancelClick() {
    if (isProcessingImages || isUploading) {
      return;
    }

    setIsOpen(false);
    setImages([]);
  }

  async function onUploadClickHandler() {
    if (isProcessingImages) {
      return;
    }

    if (shouldOpenChurnModal()) {
      setIsOpen(false);
      return;
    }
    setIsUploading(true);

    const uploadTo = {
      facebook: toggleFacebookStatus,
      google: toggleGoogleStatus,
    };
    await addPhotos(images, gallerySelectedInModal, uploadTo);

    setIsOpen(false);
    setImages([]);
    setProgress(0);
  }

  const elementStickyToBottom = (
    <section className="flex flex-col space-y-2 justify-between sm:items-center sm:my-3 sm:flex-row w-full">
      <section className="w-full flex flex-col">
        <div className="text-sm-medium text-gray-500">Select gallery</div>
        <div className="relative mt-1 sm:w-60">
          <GenericDropdown
            displayedItem={{
              text: gallerySelectedInModal,
            }}
            dropdownItems={galleriesForDropdown}
            dropDirection={isMobile ? "top-end" : "bottom-end"}
            width="w-full"
            dropdownItemWidth="w-full"
          />
        </div>
      </section>
      <section className="w-auto flex justify-start sm:mt-0 mt-2">
        <div className="flex items-center text-gray-500 mr-2 whitespace-nowrap">
          Share to
        </div>
        {renderSyncModals()}
        {renderSyncSwitches()}
      </section>
    </section>
  );

  return (
    <div>
      {renderChurnModal()}
      <UtilityModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Upload Photos"
        showCancelButton={true}
        showSubmitButton={true}
        submitButtonText="Upload"
        isSubmitDisabled={
          images.length === 0 || isUploading || uploadButtonDisabled
        }
        onSubmitHandler={onUploadClickHandler}
        submitButtonId="upload-photo-submit"
        onCancelHandler={handleOnCancelClick}
        handleClose={handleOnCancelClick}
        elementStickyToBottom={elementStickyToBottom}
        width="sm:w-700"
      >
        <section className="flex flex-col w-full h-full flex-grow sm:items-start sm:justify-start min-h-200 sm:max-h-500 sm:overflow-auto">
          <section
            className={`flex justify-center sm:h-full mb-2 sm:my-0 sm:h-full sm:w-full ${
              images.length === 0 ? "h-full" : ""
            }`}
          >
            {!(images.length > 0) && (
              <div className="flex flex-col justify-between sm:h-48 h-full w-full space-y-5 bg-white rounded-lg photo-upload-box p-2">
                <GalleryUpload
                  setIsProcessingImages={setIsProcessingImages}
                  processImages={handleImages}
                  setFiles={setFiles}
                />
              </div>
            )}
            {images.length > 0 && (
              <div className="grid grid-cols-3 gap-2 sm:gap-0 sm:flex sm:flex-wrap justify-center w-full">
                {images.map((image: any, index: number) => {
                  const firstLoadingImage = images.findIndex(
                    (image: any) => image.data === "loading"
                  );

                  if (image.data === "loading" && firstLoadingImage === index) {
                    return (
                      <div
                        className="rounded flex justify-center items-center bg-white border border-blue-500 sm:mr-2 sm:mb-2"
                        style={{
                          width: isMobile ? "100%" : 148,
                          height: isMobile ? 96 : 118,
                        }}
                      >
                        <div className="photo-upload-spinner"></div>
                      </div>
                    );
                  }

                  if (image.data === "loading" && firstLoadingImage !== index) {
                    return (
                      <div
                        className="rounded flex justify-center items-center bg-blue-50 border border-blue-200 sm:mr-2 sm:mb-2"
                        style={{
                          width: isMobile ? "100%" : 148,
                          height: isMobile ? 96 : 118,
                        }}
                      >
                        <PhotographIcon className="h-8 w-8 text-blue-400 heroicon-stroke-w-1.2" />
                      </div>
                    );
                  }

                  return (
                    <GalleryImage
                      key={index}
                      image={image}
                      className="w-full h-full object-cover"
                    />
                  );
                })}
              </div>
            )}
            {isUploading && (
              <UploadProgressBar
                progress={progress}
                uploadingText={uploadingText}
              />
            )}
          </section>
        </section>
      </UtilityModal>
    </div>
  );
};

export default UploadPhotoModal;
