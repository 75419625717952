interface WidgetFeatureSectionProps {
  bgColor: string;
}

const WidgetFeatureSection: React.FC<WidgetFeatureSectionProps> = ({
  bgColor = "bg-green-900",
  children,
}) => {
  return (
    <div
      className={`${bgColor} rounded-md px-5 sm:px-8 py-10 text-white my-4 w-full sm:w-auto`}
    >
      {children}
    </div>
  );
};

export default WidgetFeatureSection;
