import { areTheseStringEqual } from "../../../helpers/common";
import { CANCELED, LIVE, PAUSED } from "./constants";

export const getBadgeColor = (currentCampaignStatus: string) => {
  if (areTheseStringEqual(currentCampaignStatus, LIVE)) {
    return "green";
  } else if (areTheseStringEqual(currentCampaignStatus, PAUSED)) {
    return "amber";
  } else if (areTheseStringEqual(currentCampaignStatus, CANCELED)) {
    return "red";
  } else {
    return "blue";
  }
};
