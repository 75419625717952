import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import WidgetContainer from "../../dashboard/WidgetContainer/WidgetContainer";
import { useAuthInstagram } from "../../../../hooks/useAuthInstagram";
import DashboardConnections from "../../dashboard/DashboardConnections/DashboardConnections";

export default function CompleteSetupWidget() {
  const { renderInstagramErrorConnectionModal } = useAuthInstagram();

  return (
    <>
      <UnsubscribedEmptyStateWrapper>
        <WidgetContainer>
          <div className="flex justify-between items-center pb-1">
            <h1 className="text-h2-bold text-gray-900">Complete Setup</h1>
          </div>
          <div className="text-sm-normal text-gray-600">
            Topline Pro Tip: Connect your software to get the most out of
            Topline Pro
          </div>
          <DashboardConnections />
        </WidgetContainer>
      </UnsubscribedEmptyStateWrapper>

      {renderInstagramErrorConnectionModal()}
    </>
  );
}
