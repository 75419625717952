import { FC } from "react";
import * as HIcons from "@heroicons/react/outline";

export type IconName = keyof typeof HIcons;

const DynamicHeroIcon: FC<{ icon: IconName; customStyle: string }> = (
  props
) => {
  const TheIcon = HIcons[props.icon];
  // const TheIcon: JSX.Element = icons["star"];

  const customStyle = props?.customStyle;
  return (
    <>
      <TheIcon
        className={`${customStyle ? customStyle : "h-6 w-6 text-gray-500"} `}
        aria-hidden="true"
      />
    </>
  );
};

export default DynamicHeroIcon;
