import React from "react";

import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import useIsMobile from "../../../hooks/useIsMobile";
import EmptyState from "../../uiwrappers/EmptyState/EmptyState";

export default function GraphMetricsTile({
  data,
  total,
  showDollarSign,
  timePeriod,
}: any) {
  const isMobile = useIsMobile();
  let showYDataDecimal = false;
  let tickCount = 4;

  function getMaxValue(data: any) {
    let max = 0,
      min = 0;
    if (data) {
      for (const obj of data) {
        if (obj.value > max) max = obj.value;
        if (obj.value < min) min = obj.value;
      }
      if (Math.ceil(max) - Math.ceil(min) < 4) {
        showYDataDecimal = true;
        tickCount = 3;
      }
    }

    return max;
  }
  const max = getMaxValue(data);

  function tickFormatterHandlerYAxis(number: any) {
    let result = "";
    if (number === 0) result = "";
    else if (showDollarSign) {
      showYDataDecimal
        ? (result = `$${number.toFixed(1)}`)
        : (result = `$${Math.ceil(number)}`);
    } else
      max === 1
        ? (result = `${number.toFixed(1)}`)
        : (result = `${Math.ceil(number)}`);

    return result;
  }

  function tickFormatterHandlerXAxis(value: any) {
    if (timePeriod === "Today") {
      if (value < 12) {
        return `${value} am      `;
      } else if (value === 12) {
        return "12 pm";
      } else return `${value - 12} pm      `;
    } else if (timePeriod === "Last 7 days") {
      return `${value}     `;
    }
    return value;
  }

  function formatTooltip(value, name, props) {
    if (showDollarSign) return `$${value}`;
    else return value;
  }

  function getLeftMargin() {
    let digitCount;
    if (data) {
      let max = getMaxValue(data);

      if (Math.ceil(max) === 1) digitCount = 2;
      else {
        // buffer 30% showing on graph
        max = max * 1.3;
        max = Math.ceil(max);

        digitCount = max.toString().length;
      }

      if (showYDataDecimal) digitCount += 1;

      let leftMargin = 0;

      if (digitCount === 1) leftMargin = -43;
      if (digitCount === 2) leftMargin = -31;
      if (digitCount === 3) leftMargin = -21;
      if (digitCount === 4) leftMargin = -12;
      if (showDollarSign) leftMargin += 12;
      return leftMargin;
    }
    return 0;
  }

  return (
    <div>
      {(!data || data?.length === 0) && (
        <EmptyState
          header="Nothing to show yet"
          subheader="We’ll update this chart when there is data available for the selected time frame"
          icon="chart"
          customStyle="mt-0 mb-12"
          subheaderCustomPadding="px-0"
        />
      )}
      {data && data?.length !== 0 && (
        <>
          <div>
            <div className="text-2xl font-semibold mb-2">
              {showDollarSign && "$"}
              {showDollarSign ? total?.toFixed(2) : total}
            </div>
          </div>
          <div>
            <ResponsiveContainer width="100%" aspect={2}>
              <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 15,
                  right: 0,
                  left: getLeftMargin(),
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="2%" stopColor="#1D4ED8" stopOpacity={0.1} />
                    <stop offset="98%" stopColor="#FFFFFF" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="time"
                  tick={{ fill: "#9CA3AF" }}
                  axisLine={false}
                  tickLine={false}
                  padding="no-gap"
                  tickFormatter={tickFormatterHandlerXAxis}
                  style={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                />

                <YAxis
                  tick={{ fill: "#9CA3AF" }}
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={tickFormatterHandlerYAxis}
                  tickCount={tickCount}
                  style={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                />

                <Tooltip
                  contentStyle={{
                    backgroundColor: "#fff",
                    color: "#6B7280",
                    borderRadius: "8px",
                    borderWidth: "0px",
                    boxShadow: "0px 2px 9px #9CA3AF",
                  }}
                  itemStyle={{ color: "#6B7280" }}
                  cursor={false}
                  formatter={formatTooltip}
                />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#3B82F6"
                  strokeWidth="2"
                  dot={{
                    fill: "#3B82F6",
                    stroke: "#8884d8",
                    strokeWidth: 0,
                    r: 1,
                  }}
                  activeDot={{
                    fill: "#3B82F6",
                    stroke: "#8884d8",
                    strokeWidth: 0,
                    r: 8,
                  }}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
}
