import React, { useEffect, useState } from "react";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import useIsMobile from "../../../../hooks/useIsMobile";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import PaymentsWidgetTile from "../PaymentsWidgetTile/PaymentsWidgetTile";
import Link from "next/link";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import RequestPaymentModal from "../../payments/RequestPaymentModal/RequestPaymentModal";
import PaymentsWidgetNeedToSetup from "../PaymentsWidgetNeedToSetup/PaymentsWidgetNeedToSetup";
import EmptyState from "../../../uiwrappers/EmptyState/EmptyState";
import PaymentsWidgetIncompleteSetup from "../PaymentWidgetIncompleteSetup/PaymentWidgetIncompleteSetup";
import { numberWithCommas } from "../../payments/utils";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

interface PaymentsWidgetProps {
  stripeAccountId: any;
  status: any;
  openInvoices: any[];
  setOpenInvoices: (arg0: any[]) => void;
  paidInvoices: any[];
  allPayments: any[];
  requestPaymentModalOpen: boolean;
  setRequestPaymentModalOpen: (arg0: boolean) => void;
}

export default function PaymentsWidget({
  stripeAccountId,
  status,
  openInvoices,
  setOpenInvoices,
  paidInvoices,
  allPayments,
  requestPaymentModalOpen,
  setRequestPaymentModalOpen,
}: PaymentsWidgetProps) {
  const isMobile = useIsMobile();
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();
  const [displayedOpenInvoices, setDisplayedOpenInvoices] = useState<any[]>([]);
  const [displayedPaidInvoices, setDisplayedPaidInvoices] = useState<any[]>([]);
  const [searchedContact, setSearchedContact] = useState("");
  const [recipient, setRecipient] = useState([]);
  const [displayedContacts, setDisplayedContacts] = useState<any[]>([]);
  const [showMessage, setShowMessage] = useState(false);
  const [alert, setAlert] = useState("");
  const [isPaymentDetailModalOpen, setIsPaymentDetailModalOpen] =
    useState<boolean>(false);
  const [revenue, setRevenue] = useState("");
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false);

  useEffect(() => {
    const combinedArrayPayments = [];
    allPayments.forEach((payment: any) => {
      const found = paidInvoices.find(
        (invoice: any) => invoice.payment_intent === payment.id
      );
      if (found) {
        combinedArrayPayments.push(found);
      } else {
        combinedArrayPayments.push(payment);
      }
    });

    if (openInvoices.length > 1) {
      const firstTwoInvoices = openInvoices.slice(0, 2);
      setDisplayedOpenInvoices(firstTwoInvoices);
      setDisplayedPaidInvoices([]);
    } else if (openInvoices.length === 1) {
      setDisplayedOpenInvoices(openInvoices);
      if (paidInvoices.length > 0) {
        const latestPaidInvoice = paidInvoices[0];
        setDisplayedPaidInvoices([latestPaidInvoice]);
      }
    } else {
      const twoLatestInvoices = paidInvoices.slice(0, 2);
      setDisplayedOpenInvoices([]);
      setDisplayedPaidInvoices(twoLatestInvoices);
    }

    let revenue = 0;
    allPayments?.forEach((payment) => {
      revenue = revenue + payment.amount_received / 100;
    });
    let revenueString = "0";
    if (revenue !== 0) {
      revenueString = revenue.toFixed(2);
    }
    setRevenue(revenueString);
  }, [openInvoices, paidInvoices, allPayments]);

  useEffect(() => {
    if (
      openInvoices.length === 0 &&
      paidInvoices.length === 0 &&
      allPayments.length === 0
    ) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
  }, [openInvoices, paidInvoices, allPayments]);

  function startRequestPayment() {
    if (shouldOpenChurnModal()) return;
    setRequestPaymentModalOpen(true);
  }

  function onCancelHandler() {
    setRequestPaymentModalOpen(false);
    setShowMessage(false);
    setSearchedContact("");
    setRecipient([]);
    setAlert("");
  }

  return (
    <>
      {renderChurnModal()}
      <RequestPaymentModal
        type="invoice"
        requestPaymentModalOpen={requestPaymentModalOpen}
        setRequestPaymentModalOpen={setRequestPaymentModalOpen}
        searchedContact={searchedContact}
        setSearchedContact={setSearchedContact}
        recipient={recipient}
        setRecipient={setRecipient}
        displayedContacts={displayedContacts}
        setDisplayedContacts={setDisplayedContacts}
        openInvoices={openInvoices}
        setOpenInvoices={setOpenInvoices}
        onCancelHandler={onCancelHandler}
        setIsPaymentDetailModalOpen={setIsPaymentDetailModalOpen}
      />
      <UnsubscribedEmptyStateWrapper>
        <WidgetContainer>
          {!stripeAccountId && <PaymentsWidgetNeedToSetup />}
          {!!stripeAccountId && status === "incomplete" && (
            <PaymentsWidgetIncompleteSetup />
          )}
          {!!stripeAccountId && status !== "incomplete" && (
            <>
              <div className="flex justify-between items-center pb-1">
                <h1 className="text-h2-bold text-gray-900">Payments</h1>
                <div className="flex flex-row items-center">
                  <div className="hidden sm:flex sm:flex-col items-center mr-6">
                    {!showEmptyState && (
                      <>
                        <h3 className="text-h3-bold text-gray-900">
                          ${numberWithCommas(revenue) || 0}
                        </h3>
                        <div className="text-sm-normal text-gray-600">
                          Revenue
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <SecondaryButton
                      onClickFunc={startRequestPayment}
                      size="md"
                      text="Request payment"
                    />
                  </div>
                </div>
              </div>
              {showEmptyState && (
                <EmptyState
                  header="Setup is complete. You are ready to get paid."
                  subheader={`Start by clicking “Request Payment” above ${
                    isMobile ? "or by clicking “QR Code” below" : ""
                  } `}
                  icon="payment"
                />
              )}

              {!showEmptyState && (
                <>
                  {displayedOpenInvoices?.length > 0 && (
                    <h2 className="text-h3-semibold mt-4">Pending Payments</h2>
                  )}
                  {displayedOpenInvoices?.length > 0 &&
                    displayedOpenInvoices?.map((invoice, index) => {
                      return (
                        <PaymentsWidgetTile
                          key={invoice.id}
                          invoice={invoice}
                          type="invoice"
                          section="open-invoices"
                          index={index}
                          totalInvoicesInSection={displayedOpenInvoices?.length}
                        />
                      );
                    })}
                  {displayedPaidInvoices?.length > 0 && (
                    <h2 className="text-h3-semibold mt-4">Recent Payments</h2>
                  )}
                  {displayedPaidInvoices?.length > 0 &&
                    displayedPaidInvoices?.map((invoice, index) => {
                      const type = invoice?.customer
                        ? "invoice"
                        : "customer-chooses-price";
                      return (
                        <PaymentsWidgetTile
                          key={invoice.id}
                          invoice={invoice}
                          type={type}
                          section="paid-invoices"
                          index={index}
                          totalInvoicesInSection={displayedPaidInvoices?.length}
                        />
                      );
                    })}
                </>
              )}

              <div className="flex justify-center">
                <Link href="/payments">
                  <a>
                    <PrimaryLink
                      size={isMobile ? "sm" : "md"}
                      text={`View all payments`}
                      icon="right-arrow"
                      iconPlacement="lagging"
                      customStyle="font-medium"
                    />
                  </a>
                </Link>
              </div>
            </>
          )}
        </WidgetContainer>
      </UnsubscribedEmptyStateWrapper>
    </>
  );
}
