import {
  EyeIcon,
  InformationCircleIcon,
  TrendingUpIcon,
  CursorClickIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  DesktopComputerIcon,
} from "@heroicons/react/outline";

import React from "react";
import PrimaryLink from "../PrimaryLink/PrimaryLink";
import GraphMetricsTile from "./GraphMetricsTile";

export default function InsightTile({
  title,
  data,
  icon,
  customTitleComponent,
  customBodyComponent,
  hideIcon,
  customStyle,
  tooltipText,
  showInfoTooltip = true,
  customFunction,
  linkTitle,
  linkOnClickFunc = null,
  renderLinkCondition = true,
  showGraph = false,
  total,
  showDollarSign,
  timePeriod,
}: any) {
  switch (icon) {
    case "cursor-click":
      icon = (
        <CursorClickIcon
          className="w-6 h-6 sm:w-8 sm:h-8 text-white"
          style={{ strokeWidth: 1.5 }}
        />
      );
      break;
    case "currency-dollar":
      icon = (
        <CurrencyDollarIcon
          className="w-6 h-6 sm:w-8 sm:h-8 text-white"
          style={{ strokeWidth: 1.5 }}
        />
      );
      break;
    case "desktop-computer":
      icon = <DesktopComputerIcon style={{ strokeWidth: 1.5 }} />;
      break;
    case "eye":
      icon = <EyeIcon style={{ strokeWidth: 1.5 }} />;
      break;
    case "trending-up":
      icon = <TrendingUpIcon style={{ strokeWidth: 1.5 }} />;
      break;
    case "calendar":
      icon = <CalendarIcon style={{ strokeWidth: 1.5 }} />;
      break;
    case "credit-card":
      icon = <CreditCardIcon style={{ strokeWidth: 1.5 }} />;
      break;
    default:
      break;
  }

  return (
    <>
      <div
        className={`flex bg-white shadow rounded-lg ${
          !showGraph
            ? `p-6 ${
                hideIcon && "px-5 py-6"
              } h-28 sm:h-auto mx-1 sm:w-80 ${customStyle}`
            : `flex-col  px-6 pt-6 pb-4 ${
                !showDollarSign && "pb-1"
              }  h-auto ${customStyle}`
        }`}
      >
        <div className="flex h-full items-center">
          {!hideIcon && (
            <div className="bg-blue-900 rounded-lg w-12 h-12 p-3 sm:w-16 sm:h-16 sm:p-4 mr-5">
              <div className="w-6 h-6 sm:w-8 sm:h-8 text-white">{icon}</div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-around w-full">
          {customTitleComponent ? (
            customTitleComponent
          ) : (
            <>
              <div className="flex flex-wrap w-full justify-between">
                <div className="flex flex-row items-center">
                  <h1 className="flex text-gray-500 text-sm-medium mr-1 whitespace-nowrap">
                    {title}
                  </h1>
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={tooltipText}
                    data-tooltip-place="top"
                  >
                    {showInfoTooltip && (
                      <InformationCircleIcon
                        className="flex w-5 h-5 text-gray-500 cursor-pointer"
                        style={{ strokeWidth: 1.5 }}
                      />
                    )}
                  </a>
                  {/* {} <Tooltip id="my-tooltip" place="top" className="tooltip" /> */}
                </div>
                {linkTitle && renderLinkCondition && (
                  <div className="flex justify-end">
                    <PrimaryLink
                      id={`update-gg-ads-budget`}
                      text={linkTitle}
                      size="md"
                      onClickFunc={linkOnClickFunc}
                      customStyle={"my-1 sm:my-0"}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {customBodyComponent && customBodyComponent}
          {!showGraph && (
            <div className="flex items-center">
              {data !== "connect" && (
                <div className="flex text-h1-semibold mr-3 whitespace-nowrap">
                  {data || data === 0 ? (
                    data
                  ) : (
                    <div className="h-8 w-8 animate-pulse bg-gray-200"></div>
                  )}
                </div>
              )}
              {data === "connect" && (
                <div>
                  <PrimaryLink
                    text="Connect to Google"
                    size="lg"
                    onClickFunc={customFunction}
                    customStyle="whitespace-nowrap"
                  />
                </div>
              )}
            </div>
          )}
          {showGraph && (
            <GraphMetricsTile
              data={data}
              total={total}
              showDollarSign={showDollarSign}
              timePeriod={timePeriod}
            />
          )}
        </div>
      </div>
    </>
  );
}
