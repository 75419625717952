/* This example requires Tailwind CSS v2.0+ */
import React, { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";

export default function StripePopup() {
  const [open, setOpen] = useState(true);
  const router = useRouter();

  function closeModal() {
    setOpen(false);
  }

  function goToPayments() {
    setOpen(false);
    router.push("/payments");
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:px-8 sm:py-6`}
                >
                  <button
                    type="button"
                    className="absolute top-0 right-0 pt-4 pr-4 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <>
                    <div className="flex flex-col items-center justify-center text-center">
                      <div className="-my-8">
                        <img src="/yeti.png" className="h-60 w-60" alt="yeti" />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <h1 className="text-h2-semibold text-gray-900">
                          Win a Topline Pro Yeti Cooler
                        </h1>
                        <h3 className="text-body-normal text-gray-900">
                          Get rewarded for getting paid through Topline Pro
                        </h3>
                        <div className="text-sm-normal text-gray-500">
                          For every $1 you get paid, you’ll get 1 entry, plus a
                          bonus 100 entries after you make your first payment.
                        </div>
                      </div>
                    </div>
                    <div className="hidden sm:flex flex-row mt-10">
                      <SecondaryButton
                        size="md"
                        text="I don't want a Yeti"
                        onClickFunc={closeModal}
                        customStyle="w-full mr-2"
                      />
                      <PrimaryButton
                        size="md"
                        text="Go to Payments"
                        onClickFunc={goToPayments}
                        customStyle="w-full"
                      />
                    </div>
                    <div className="sm:hidden flex flex-row mt-6">
                      <SecondaryButton
                        size="xs"
                        text="I don't want a Yeti"
                        onClickFunc={closeModal}
                        customStyle="w-full mr-2"
                      />
                      <PrimaryButton
                        size="xs"
                        text="Go to Payments"
                        onClickFunc={goToPayments}
                        customStyle="w-full"
                      />
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
