import { api } from "../../../helpers/topline-api";

export async function getCampaignMetrics(
  campaignId: string,
  googleAdsAccountId: string,
  time_period: string
) {
  const response = await api.post(`/ads/get-campaign-metrics-by-time-period`, {
    campaign_id: campaignId,
    customer_id: googleAdsAccountId,
    time_period: time_period,
  });
  const { data } = response;

  const { message } = data;
  return message;
}

export async function getKeywordMetrics(
  campaignId: string,
  googleAdsAccountId: string,
  time_period: string
) {
  const response = await api.post(`/ads/get-keyword-metrics-by-time-period`, {
    campaign_id: campaignId,
    customer_id: googleAdsAccountId,
    time_period: time_period,
  });
  const { data } = response;

  const { message } = data;
  return message;
}

export function getTimePeriod(timePeriod: string) {
  let time_period = "";
  switch (timePeriod) {
    case "Today":
      time_period = "TODAY";
      break;
    case "Last 7 days":
      time_period = "LAST_7_DAYS";
      break;
    case "Last 30 days":
      time_period = "LAST_30_DAYS";
      break;
    case "Last 12 months":
      time_period = "LAST_12_MONTHS";
      break;
  }
  return time_period;
}
