import { InformationCircleIcon } from "@heroicons/react/outline";
import React from "react";

function TooltipForMetricsTile({ tooltipText, textPosition = "top" }: any) {
  return (
    <>
      <a
        data-tooltip-id="my-tooltip"
        data-tooltip-content={tooltipText}
        data-tooltip-place={textPosition}
      >
        <InformationCircleIcon
          className="flex w-5 h-5 text-gray-500 cursor-pointer"
          style={{ strokeWidth: 1.5 }}
        />
      </a>
    </>
  );
}

export default TooltipForMetricsTile;
