const WidgetSubscriptionInfoSection: React.FC = ({ children }) => {
  return (
    <div className="flex flex-row sm:flex-col justify-center items-center">
      <div className="flex w-full justify-between sm:align-middle sm:items-center sm:flex-col sm:space-y-2 sm:space-x-0 ">
        {children}
      </div>
    </div>
  );
};

export default WidgetSubscriptionInfoSection;
