import React from "react";
interface LoadingSuccessGraphicProps {
  loading: boolean;
  success: boolean;
}

export const LoadingSuccessGraphic = ({
  loading,
  success,
}: LoadingSuccessGraphicProps) => {
  return (
    <div className={`circle-loader ${success && "load-complete"}`}>
      <div
        key={loading ? "no-checkmark" : "checkmark"}
        className={`checkmark draw`}
        style={{ display: success ? "block" : "none" }}
      ></div>
    </div>
  );
};
