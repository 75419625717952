import { IAllMetricsDefault } from "./types";

export const timePeriods = [
  "Today",
  "Last 7 days",
  "Last 30 days",
  "Last 12 months",
];

export const ALL_METRICS_DEFAULT: IAllMetricsDefault = {
  cost: {
    total: 0,
    details: [],
  },
  clicks: {
    total: 0,
    details: [],
  },
  conversions: {
    total: 0,
    details: [],
  },
  impressions: {
    total: 0,
    details: [],
  },
  cost_per_conversion: {
    total: 0,
    details: [],
  },
  cost_per_click: {
    total: 0,
    details: [],
  },
};

export const CAMPAIGN_SPEND_TOOL_TIP =
  "Campaign spend is the sum of your spend during this period.";
export const CLICKS_TOOL_TIP =
  "When someone clicks your ad during this period, it's counted here.";
export const CONVERSION_TOOL_TIP =
  'Conversions" shows the number of conversions you received after ad interactions (such as text ad clicks or phone calls) during this period';
export const IMPRESSION_TOOL_TIP =
  "An impression is counted each time your ad is served. Impressions help you understand how often your ad is being seen.";
export const COST_PER_CONVERSION_TOOL_TIP =
  "Cost per conversion (“Cost/conv.”) shows the average cost of a conversion during this period. It’s your cost divided by your conversions.";
export const COST_PER_CLICK =
  "The amount you've paid for your ad divided by its total clicks during this period. If your ad receives 2 clicks, one costing $0.20 and one costing $0.40, your average CPC for those clicks is $0.30.";
