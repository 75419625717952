import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import GenericBadge from "../../../../uiwrappers/GenericBadge/GenericBadge";
import { useRouter } from "next/router";
import SecondaryButton from "../../../../uiwrappers/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../../uiwrappers/PrimaryButton/PrimaryButton";

interface PhysicalMarketingReleaseModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

export const PhysicalMarketingReleaseModal: React.FC<
  PhysicalMarketingReleaseModalProps
> = ({ isOpen, setOpen }: PhysicalMarketingReleaseModalProps) => {
  const router = useRouter();

  if (!isOpen) {
    return <></>;
  } else {
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="z-40 fixed inset-0 sm:h-auto sm:overflow-hidden"
          onClose={() => setOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen text-center px-4 sm:px-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              unmount={true}
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-0"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-0 translate-y-0 sm:scale-100"
              leaveTo="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="flex flex-col z-40 fixed sm:relative sm:inline-block align-bottom rounded-xl shadow-xl sm:align-middle bg-gray-50
                  sm:max-h-screen mx-4 sm:mx-0 max-w-max sm:max-w-lg sm:w-512 h-auto sm:overflow-visible pb-6"
              >
                <div className="flex justify-center items-center bg-blue-shapes-modal-mobile sm:bg-blue-shapes-modal-desktop bg-cover p-4 sm:p-6 rounded-t-xl">
                  <div className="flex flex-col items-center bg-gray-50 rounded-lg py-6 sm:py-5 px-6">
                    <p className="text-sm-medium text-gray-900 text-center mb-5">
                      "Yard signs are a game-changer! After every job, I ask
                      customers if they mind displaying one. It's significantly
                      boosted my offline discovery — I highly recommend them!"
                    </p>
                    <div className="flex">
                      <img
                        src="/john-olsen-icon.png"
                        alt="John Olsen"
                        className="rounded-full mr-3"
                        width={40}
                        height={40}
                      />
                      <div className="flex flex-col">
                        <p className="text-sm-medium text-gray-900 text-left">
                          John Olsen
                        </p>
                        <p className="text-sm text-gray-700 text-left">
                          J&J House Washing
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center pt-5 px-6 w-full">
                  <GenericBadge
                    text="NEW FEATURE"
                    color="green"
                    backgroundColorLevel="600"
                    invertedColors={true}
                    textWeightNormal={true}
                  />
                  <p className="mt-2.5 mb-4 px-5 text-2xl">
                    Now get business cards, yard signs, and more
                  </p>
                  <p className="px-5 text-body text-gray-500">
                    Designed for you, printed with high-quality materials, and
                    shipped directly to your door
                  </p>
                  <div className="flex flex-col sm:flex-row items-center mt-8 w-full">
                    <SecondaryButton
                      customStyle="order-last sm:order-first sm:mr-3 w-full sm:w-56"
                      text="No Thanks"
                      size="md"
                      onClickFunc={() => setOpen(false)}
                    />
                    <PrimaryButton
                      customStyle="order-first sm:order-last w-full mb-3 sm:mb-0 sm:w-56"
                      text="Get Started"
                      size="md"
                      onClickFunc={() =>
                        router.push("/marketing/physical-marketing")
                      }
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
};
