import Fuse from "fuse.js";
import { URL_CONSTANTS } from "../../constants/urlConstants";
import { AssistantAction, ContactActions } from "./types";
import { api } from "../../helpers/topline-api";

export class AssistantService {
  static async getAllActions(): Promise<AssistantAction[]> {
    const response = await api.get(`/assistant/assistant-actions`);
    return response.data.records;
  }

  static async assistantSearch(
    search_term: string,
    limit: number
  ): Promise<AssistantAction[]> {
    try {
      const response = await api.get(
        `${URL_CONSTANTS.PROSITE_SEARCH_WITH_SUPER_ASSISTANT}`,
        { params: { search_term, limit } }
      );

      return response.data.records;
    } catch (e) {
      return [];
    }
  }

  static async increaseActionCount(id: number): Promise<void> {
    api.put(`/assistant/assistant-actions/${id}/increase-times-used`);
  }
}

export function classNames(...classes: Array<string | boolean>): string {
  return classes.filter(Boolean).join(" ");
}

export function handleFuseSearch(
  actions: AssistantAction[],
  contactActions: ContactActions,
  query: string
): AssistantAction[] {
  const options = {
    minMatchCharLength: 2,
    includeScore: true,
    findAllMatches: true,
    ignoreLocation: true,
    threshold: 0.5,
    keys: ["title"],
  };

  const lowerTrimmedQuery = query.toLowerCase().trim().slice(0, -1);

  let fuse;
  if (lowerTrimmedQuery.includes("send a review request to ")) {
    options.threshold = 0;
    options.findAllMatches = false;
    fuse = new Fuse(contactActions.reviewRequest, options);
  } else if (lowerTrimmedQuery.includes("view contact for ")) {
    options.threshold = 0;
    options.findAllMatches = false;
    fuse = new Fuse(contactActions.viewContact, options);
  } else if (lowerTrimmedQuery.includes("send a payment request to ")) {
    options.threshold = 0;
    options.findAllMatches = false;
    fuse = new Fuse(contactActions.viewContact, options);
  } else {
    fuse = new Fuse(actions, options);
  }

  const result = fuse.search(query);
  return result.map((el: Fuse.FuseResult<AssistantAction>) => el.item);
}

export const hotkey = (): string => {
  if (navigator.appVersion.indexOf("Mac")) {
    return "⌘K";
  } else if (navigator.appVersion.indexOf("Win")) {
    return "WinK";
  } else {
    return "Command + K";
  }
};
