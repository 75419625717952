import Link from "next/link";
import { useContext, useMemo } from "react";
import useCampaignMetricFetch, {
  IUseCampaignMetricResponse,
} from "../../hooks/useCampaignMetricFetch/useCampaignMetricFetch";
import { CampaignMetricsAnalyticService } from "../../services/campaignMetricsAnalyticService/campaignMetricsAnalyticService";
import {
  CAMPAIGN_SPEND_TOOL_TIP,
  CLICKS_TOOL_TIP,
  CONVERSION_TOOL_TIP,
} from "../GoogleAdsDashBoard/CampaignHistoricMetrics/constants";
import { getBadgeColor } from "../GoogleAdsDashBoard/GoogleAdsDashBoardComponent/utils";
import WidgetFooter from "../WidgetFooter/WidgetFooter";
import WidgetHeader from "../WidgetHeader/WidgetHeader";
import WidgetHeading from "../WidgetHeading/WidgetHeading";
import WidgetMeticTiles from "../WidgetMeticTiles/WidgetMeticTiles";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import WidgetContainer from "../primaryrender/dashboard/WidgetContainer/WidgetContainer";
import EmptyState from "../uiwrappers/EmptyState/EmptyState";
import GenericBadge from "../uiwrappers/GenericBadge/GenericBadge";
import PrimaryLink from "../uiwrappers/PrimaryLink/PrimaryLink";
import useIsMobile from "../../hooks/useIsMobile";

const AdsDashBoardWidgetActive: React.FC = () => {
  const { profileInfo, userCompleteCampaignInfo } = useContext(DesktopContext);
  const isMobile = useIsMobile();
  const { selectedBudgetAmount, selectedEndDate, campaign_status } =
    userCompleteCampaignInfo;
  const campaignId = userCompleteCampaignInfo?.campaign_id;

  const googleAdsAccountId = profileInfo?.google_ads_account_id;
  const {
    campaignMetricData,
    campaignMetricDataError,
  }: IUseCampaignMetricResponse = useCampaignMetricFetch({
    campaignId,
    googleAdsAccountId,
  });

  const campaignAnalyticService = new CampaignMetricsAnalyticService(
    campaignMetricData || {}
  );

  const totalCampaignCost = useMemo(() => {
    return campaignAnalyticService.getTotalCost();
  }, [campaignMetricData]);

  const totalCampaignClicks = useMemo(() => {
    return campaignAnalyticService.getTotalClicks();
  }, [campaignMetricData]);
  const totalCampaignConversion = useMemo(() => {
    return campaignAnalyticService.getTotalConversions();
  }, [campaignMetricData]);

  const latestCostChange = useMemo(() => {
    return campaignAnalyticService.getLastTwoPeriodCostDifference();
  }, [campaignMetricData]);

  const lastClicksChange = useMemo(() => {
    return campaignAnalyticService.getLastTwoPeriodClicksDifference();
  }, [campaignMetricData]);

  const lastConversionChange = useMemo(() => {
    return campaignAnalyticService.getLastTwoPeriodConversionsDifference();
  }, [campaignMetricData]);

  const showEmptyState =
    !campaignMetricData ||
    campaignMetricDataError ||
    campaign_status.toLowerCase() !== "live";

  return (
    <WidgetContainer>
      <WidgetHeader>
        <WidgetHeading>Marketing</WidgetHeading>
        <div className="mt-2 sm:mt-0 flex space-x-5">
          {selectedBudgetAmount && (
            <div className="flex flex-col justify-center items-center">
              <div className=" flex space-x-1">
                <div className="text-h3-bold text-gray-900">
                  ${selectedBudgetAmount}/ day
                </div>
              </div>
              <div>
                <div className="text-sm-normal whitespace-nowrap text-gray-600">
                  Google Budget
                </div>
              </div>
            </div>
          )}
          {selectedEndDate && (
            <div className=" flex flex-col justify-center align-middle items-center space-x-1">
              <div className="text-h3-bold text-gray-900">
                {selectedEndDate}
              </div>
              <div className="text-sm-normal whitespace-nowrap text-gray-600">
                Ends on
              </div>
            </div>
          )}
        </div>
      </WidgetHeader>

      <div className="flex mt-2 items-center">
        <p className="text-h3-medium text-gray-900 mr-3">Google Ad Campaign</p>
        <GenericBadge
          text={campaign_status}
          color={getBadgeColor(campaign_status)}
        />
      </div>

      {showEmptyState && (
        <>
          <div className="mt-2">
            <EmptyState
              header="Nothing to show yet"
              subheader="We’ll update this chart when there is data available for the selected time frame"
              icon="chart"
              customStyle="pt-0 pb-12"
              subheaderCustomPadding="px-0"
            />
          </div>
        </>
      )}
      {!showEmptyState && (
        <>
          <div className="mt-2">
            <p className="text-body-normal text-gray-900">Last 30 days</p>
          </div>
          <div className="mt-2 shadow-sm rounded-md">
            <div className="flex flex-col sm:flex-row justify-evenly sm:items-center sm:space-x-2">
              <WidgetMeticTiles
                title="Campaign Spend"
                value={`$${totalCampaignCost}`}
                change={latestCostChange}
                tooltipText={CAMPAIGN_SPEND_TOOL_TIP}
                borderStyle="border-b sm:border-r sm:border-b-0"
              />
              <WidgetMeticTiles
                title="Clicks"
                value={totalCampaignClicks}
                change={lastClicksChange}
                tooltipText={CLICKS_TOOL_TIP}
                borderStyle="border-b sm:border-r sm:border-b-0"
              />
              <WidgetMeticTiles
                title="Conversions"
                value={totalCampaignConversion}
                change={lastConversionChange}
                tooltipText={CONVERSION_TOOL_TIP}
                borderStyle="border-b sm:border-b-0"
              />
            </div>
          </div>
        </>
      )}

      <WidgetFooter>
        <Link href={`/marketing`}>
          <a>
            <PrimaryLink
              size={isMobile ? "sm" : "md"}
              text={`View Campaign Details`}
              icon="right-arrow"
              iconPlacement="lagging"
              customStyle="font-medium"
            />
          </a>
        </Link>
      </WidgetFooter>
    </WidgetContainer>
  );
};

export default AdsDashBoardWidgetActive;
