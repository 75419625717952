import React, { ReactElement, useContext, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import axios from "axios";
import ActionModal from "../../../common/ActionModal/ActionModal";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";

const FreeAnalysisTagLine = ({
  clickHereCss = "underline",
}: {
  clickHereCss?: string;
}): ReactElement => {
  const { profileInfo, basicInfo } = useContext(DesktopContext);
  const [clickHereModal, setClickHereModal] = useState(false);
  const handleClickHereCallback = () => {
    const url = "https://hooks.zapier.com/hooks/catch/7165161/33n4kjw/";
    const params = {
      first_name: profileInfo?.first_name,
      last_name: profileInfo?.last_name,
      slug: profileInfo?.slug,
      phone: basicInfo?.phone,
      email: profileInfo?.email,
      company_name: profileInfo?.company_name,
      landing_page_id: basicInfo?.sitePk,
    };
    const query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const finalUrl = url + "?" + query;

    axios.post("/api/zapier/makeZapierCall", {
      url: finalUrl,
    });
    setClickHereModal(true);
  };
  return (
    <>
      <p className="text-sm-normal cursor-pointer whitespace-pre-wrap sm:flex gap-1">
        Want a free analysis for your business?
        <PrimaryLink
          text="Click here"
          customStyle="whitespace-nowrap"
          size="md"
          onClickFunc={handleClickHereCallback}
        />
      </p>
      <ActionModal
        open={clickHereModal}
        setOpen={setClickHereModal}
        header="Free, customized ads analysis"
        subheader={`We'll take a look at your business and reach out in 48 hours with recommendations specifically for you.`}
        primaryButtonText="Confirm"
        primaryButtonFunction={() => {
          setClickHereModal(false);
        }}
        secondaryButtonFunction={() => {
          setClickHereModal(false);
        }}
        loading={false}
      />
    </>
  );
};

export default FreeAnalysisTagLine;
