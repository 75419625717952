import { useContext, useMemo, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { SelectedSiteContext } from "../../../../contexts/SelectedSiteContextProvider/SelectedSiteContextProvider";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Drawer } from "@mui/material";

export const MobileSiteSelector = () => {
  const { profileInfo } = useContext(DesktopContext);
  const { selectedSiteId, setSelectedSiteId } = useContext(SelectedSiteContext);
  const [open, setOpen] = useState(false);

  const selectedSite = useMemo(() => {
    if (!profileInfo) return;
    return profileInfo.landing_pages.find(
      ({ landing_page_pk }) => landing_page_pk == selectedSiteId
    );
  }, [profileInfo, selectedSiteId]);

  const sites = useMemo(() => {
    if (!profileInfo) return [];
    return profileInfo.landing_pages;
  }, [profileInfo]);

  const handleSiteSelection = (page_key: number) => {
    setSelectedSiteId(page_key);
    setOpen(false);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  if (sites.length <= 1) return <></>;

  return (
    <>
      <button
        onClick={toggleDrawer(true)}
        className="md:hidden text-lg flex flex-row px-4 mt-4 font-semibold text-gray-900 items-center justify-left"
      >
        {selectedSite?.company_name}
        <ChevronDownIcon className="w-4 h-4 ml-2 mr-2 text-gray-500"></ChevronDownIcon>
      </button>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        transitionDuration={300}
      >
        <div className="py-3 px-6 flex flex-row justify-between items-center">
          <span className="font-medium text-gray-900 text-sm">
            My Businesses
          </span>
          <XIcon
            className="w-5 h-5 text-gray-500 cursor-pointer"
            onClick={toggleDrawer(false)}
          ></XIcon>
        </div>
        {sites.map((item) => (
          <div
            key={item.landing_page_pk}
            className={`flex flex-row items-center justify-between py-3 px-6 ${
              item.landing_page_pk === selectedSiteId
                ? "bg-blue-50"
                : "hover:bg-gray-100 cursor-pointer"
            } `}
            onClick={() => handleSiteSelection(item.landing_page_pk)}
          >
            <div>
              <p className="font-semibold text-gray-900 text-sm">
                {item.company_name} {item.landing_page_pk}
              </p>
              <p className="font-normal text-gray-500 text-xs">{item.domain}</p>
            </div>
            {item.landing_page_pk === selectedSiteId ? (
              <CheckIcon className="w-5 h-5 text-blue-500" />
            ) : (
              <ChevronRightIcon className="w-5 h-5 text-gray-500" />
            )}
          </div>
        ))}
      </Drawer>
    </>
  );
};
