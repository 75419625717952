import {
  findFbGooglePosts,
  getFormattedDateString,
  getHeightAndWidthFromDataUrl,
} from "../utils";
import { AxiosResponse } from "axios";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { fetchNewImage } from "../../marketing/DigitalMedia/utils";
import { api } from "../../../../helpers/topline-api";
import { PhotoQueueItem } from "../PhotoQueue/utils";

export async function getPhotoDimensions(attachedPhotos: any[]): Promise<any> {
  try {
    if (attachedPhotos.length > 0 && attachedPhotos[0].type !== "video") {
      return await getHeightAndWidthFromDataUrl(attachedPhotos[0]?.url);
    }
    return {};
  } catch (e) {
    return {};
  }
}

export async function uploadBufferToS3(imageBuffer) {
  const fileName = `${uuidv4()}.png`;
  const S3_BUCKET = "landing-page-app-hero-images";

  const s3Client = new S3Client({
    region: "us-east-2",
    credentials: {
      accessKeyId: process.env.NEXT_PUBLIC_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.NEXT_PUBLIC_S3_SECRET_ACCESS_KEY,
    },
  });

  const s3Params = {
    Bucket: S3_BUCKET,
    Key: `media/${fileName}`,
    ContentType: "image/png",
    Body: imageBuffer,
    ACL: "public-read",
  };

  try {
    const result = await s3Client.send(new PutObjectCommand(s3Params));
    console.log(`Successfully uploaded to S3: ${result}`);
  } catch (error) {
    console.error(`Error uploading to S3: ${error}`);
  }

  return fileName;
}

export function setProfileAndCoverPhoto(
  isFacebookAuthenticated: boolean,
  facebookSocialPhotos: any,
  isGmbAuthenticated: boolean,
  googleSocialPhotos: any
) {
  let profilePhoto, coverPhoto;

  if (isFacebookAuthenticated) {
    profilePhoto = facebookSocialPhotos?.picture?.data.url;
    coverPhoto = facebookSocialPhotos?.cover?.source;
  } else if (isGmbAuthenticated) {
    const gmbLogo = googleSocialPhotos?.mediaItems?.find(
      (photo: any) => photo.locationAssociation.category === "PROFILE"
    );
    profilePhoto = gmbLogo?.googleUrl;
    coverPhoto = "";
  }

  return { profilePhoto, coverPhoto };
}

export function getFormattedSocialPosts(
  isFacebookAuthenticated: boolean,
  fbPosts: any,
  isGmbAuthenticated: boolean,
  gmbVerificationStatus: string,
  gmbPosts: any[],
  userInstagramPosts: any[],
  isInstagramAuthenticated: boolean
) {
  let formattedFbPosts: any[] = [];
  const formattedGmbPosts: any[] = [];
  let formattedInstagramPosts: any = [];
  const fbFeedTextContent: Record<string, Date> = {};
  const dupeHash: Record<string, { created_time: Date; google_id: string }> =
    {};

  if (isFacebookAuthenticated) {
    try {
      const posts = fbPosts?.data;
      formattedFbPosts =
        posts?.map((post: any) => {
          const message = post.message?.trim() || "";
          const key = message + getFormattedDateString(post.created_time);
          fbFeedTextContent[key] = post.created_time;

          return { ...post, origin: { facebook: post.id } };
        }) || [];
    } catch (err) {
      toast.error(
        "Unable to get your posts from Facebook. Try reconnecting your Facebook."
      );
    }
  }

  if (isGmbAuthenticated && gmbVerificationStatus === "verified") {
    try {
      gmbPosts = gmbPosts || [];
      for (let i = 0; i < gmbPosts?.length; i++) {
        const message = gmbPosts[i]?.summary?.trim() || "";
        const full_picture =
          (gmbPosts[i].media && gmbPosts[i].media[0].googleUrl) || "";
        const created_time = gmbPosts[i].createTime;
        const key = message + getFormattedDateString(created_time);

        // Ignore REJECTED posts - causes confusion with pros, better to not render at all
        if (gmbPosts[i].state === "REJECTED") continue;

        if (!fbFeedTextContent[key]) {
          formattedGmbPosts.push({
            created_time,
            full_picture,
            id: gmbPosts[i].name,
            message,
            origin: { google: gmbPosts[i].name },
            state: gmbPosts[i].state,
          });
        } else {
          dupeHash[key] = {
            created_time: created_time,
            google_id: gmbPosts[i].name,
          };
        }
      }
    } catch (e) {
      console.log("Error fetching Google My Business feed");
    }
  }

  if (isInstagramAuthenticated) {
    formattedInstagramPosts = userInstagramPosts;
  }

  const allPosts = [
    ...formattedGmbPosts,
    ...formattedFbPosts,
    ...formattedInstagramPosts,
  ];

  const allFilteredPosts = allPosts.map((post) => {
    let finalPost = { ...post };

    const message = post.message?.trim() || "";
    const created_time = post.created_time;
    const key = message + getFormattedDateString(created_time);
    if (dupeHash[key]) {
      finalPost = {
        ...post,
        origin: {
          google: dupeHash[key].google_id,
          facebook: post.origin.facebook,
        },
      };
    }
    return finalPost;
  });

  const sortedPosts = allFilteredPosts.sort(function (x, y) {
    const xDate: any = new Date(x.created_time);
    const yDate: any = new Date(y.created_time);
    return yDate - xDate;
  });

  return sortedPosts;
}

export function getFormattedScheduledPosts(userSchedulePosts: any[]) {
  const scheduledPostsNoDupes = findFbGooglePosts(userSchedulePosts);
  return scheduledPostsNoDupes;
}

export async function fetchNewDigitalMedia(
  slug,
  setNewMediaLoading,
  setAttachingPhoto,
  attachedPhotos,
  setAttachedPhotos,
  handleGeneratePost,
  newMessage,
  footer,
  setReplaceMessageModalOpen
) {
  const photo = await fetchNewImage(slug);

  const photoUuid = photo?.s3_url;
  const photoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${photoUuid}`;
  const newPhotoArray = [
    {
      ...photo,
      url: photoUrl,
      photo: photoUrl,
      type: "digital_asset",
    },
    ...attachedPhotos,
  ];

  setNewMediaLoading(false);
  setAttachingPhoto(false);

  setAttachedPhotos(newPhotoArray);

  if (newMessage && footer?.trim() !== newMessage?.trim()) {
    setReplaceMessageModalOpen(true);
  } else {
    handleGeneratePost(newPhotoArray);
  }
}

export async function getPhotoQueueItems(
  slug: string
): Promise<Array<PhotoQueueItem>> {
  try {
    const { data }: AxiosResponse = await api.get(
      `api/photo-queue-items/${slug}`
    );
    return data;
  } catch (e) {
    console.error(`An error occurred when fetching photo queue: ${e}`);
    return [];
  }
}

export async function updatePhotoQueueItems(
  slug: string,
  photoQueueItems: PhotoQueueItem[]
): Promise<void> {
  try {
    await api.put(`api/photo-queue-items/${slug}`, photoQueueItems);
  } catch (e) {
    console.error(`An error occurred when updating photo queue: ${e}`);
  }
}
