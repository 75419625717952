import { useMemo } from "react";
import TooltipForMetricsTile from "../GoogleAdsDashBoard/TooltipForMetricsTile/TooltipForMetricsTile";
import { WidgetMeticTilesProps } from "./type";
import { getChangeColor } from "./utils";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/outline";

const WidgetMeticTiles: React.FC<WidgetMeticTilesProps> = ({
  title,
  value,
  change,
  tooltipText,
  borderStyle,
}) => {
  const getChangeIcon = (change: number) => {
    const iconStyle = "w-4 h-4 font-s";
    if (change === 0) {
      return null;
    } else if (change > 0) {
      return <ArrowUpIcon className={iconStyle} />;
    } else {
      return <ArrowDownIcon className={iconStyle} />;
    }
  };

  const showChange = useMemo(() => {
    try {
      return Math.abs(change as number) > 0;
    } catch {
      return false;
    }
  }, [change]);

  return (
    <div className={`flex flex-col w-full h-full p-4 ${borderStyle}`}>
      <div className="flex space-x-1">
        <h1 className="text-sm-medium text-gray-500">{title}</h1>
        <TooltipForMetricsTile tooltipText={tooltipText} />
      </div>
      <div className="flex space-x-3 justify-start items-end align-bottom">
        <div>
          <p className="flex text-h1-semibold whitespace-nowrap">{value}</p>
        </div>
        {showChange && (
          <div
            className={`text-${getChangeColor(
              change as number
            )}-600 flex space-x-1 items-center mb-1 text-sm-semibold`}
          >
            {getChangeIcon(change as number)}
            <p>{Math.abs(change as number)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetMeticTiles;
