import { UserCircleIcon } from "@heroicons/react/solid";
import Image from "next/future/image";
import React from "react";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
interface ConnectionsBoxProps {
  header: string;
  subheader: string | React.ReactElement;
  renderButton: () => React.ReactElement;
  icon?: string;
  showDontUse?: boolean;
  dontUseFunction?: () => void;
  gmbEmail?: string;
  fbPageName?: string;
  errorMessage?: string;
}

export default function ConnectionsBox({
  header,
  subheader,
  renderButton,
  icon,
  showDontUse,
  dontUseFunction,
  gmbEmail,
  fbPageName,
  errorMessage,
}: ConnectionsBoxProps) {
  const renderIcon = () => {
    if (icon == "google") {
      return (
        <Image
          src="/google-logo.png"
          alt="Google Logo"
          width={32}
          height={32}
        />
      );
    } else if (icon === "google-with-border") {
      return (
        <div className="w-8 h-8 p-1 bg-white rounded-lg border border-gray-200 justify-center items-center inline-flex">
          <Image
            src="/google-logo.png"
            alt="Google Logo"
            width={20}
            height={20}
            layout="raw"
          />
        </div>
      );
    } else if (icon == "facebook") {
      return (
        <Image
          src="/facebook_logo.png"
          alt="Facebook Logo"
          width={32}
          height={32}
          layout="raw"
        />
      );
    } else if (icon == "facebook-with-border") {
      return (
        <div className="w-8 h-8 p-1 bg-white rounded-lg border border-gray-200 justify-center items-center inline-flex">
          <Image
            src="/facebook_logo.png"
            alt="Facebook Logo"
            width={20}
            height={20}
            layout="raw"
          />
        </div>
      );
    } else if (icon == "quickbooks") {
      return (
        <Image
          src="/quickbook_logo.svg"
          alt="Quickbooks Logo"
          width={32}
          height={32}
          layout="raw"
        />
      );
    } else if (icon === "google-contacts") {
      return (
        <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
          <Image
            layout="raw"
            src="/google-contacts-icon.svg"
            alt="Google Contacts Logo"
            width={19}
            height={23}
            className="rounded-full bg-blue-100"
          />
        </div>
      );
    } else if (icon === "instagram") {
      return (
        <Image
          src="/instagram_logo.png"
          alt="Instagram Logo"
          width={32}
          height={32}
          layout="raw"
        />
      );
    } else if (icon === "instagram-with-border") {
      return (
        <div className="w-8 h-8 p-1 bg-white rounded-lg border border-gray-200 justify-center items-center inline-flex">
          <Image
            src="/instagram_logo.png"
            alt="Instagram Logo"
            width={20}
            height={20}
            layout="raw"
          />
        </div>
      );
    } else if (icon === "contacts-with-border") {
      return (
        <div className="w-8 h-8 p-1 bg-white rounded-lg border border-gray-200 justify-center items-center inline-flex">
          <div style={{ width: "25px", height: "25px" }}>
            <UserCircleIcon
              className="text-blue-900"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex rounded-md sm:flex-row flex-col justify-between sm:items-center sm:h-auto">
      <div className="flex flex-row sm:justify-between items-center h-full w-full">
        <div className="flex flex-col space-y-2 sm:space-y-1 sm:mb-0 w-full sm:mr-8">
          <div className="flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:items-center sm:space-x-3">
            <div className="flex sm:flex-row flex-col items-start sm:space-x-3 sm:w-3/5">
              <div className="flex items-center gap-2">
                {renderIcon()}
                <span className="sm:hidden block">{header}</span>
              </div>

              <div className="w-full text-body-medium text-gray-900 sm:mr-3 ">
                <span className="sm:block hidden">{header}</span>
                <div className="mt-1 flex w-full justify-between items-center text-sm-normal text-gray-500 gap-1.5">
                  <div className="">
                    {subheader}
                    {errorMessage && (
                      <div className="text-sm-normal text-red-600">
                        {errorMessage}
                      </div>
                    )}
                    {showDontUse && (
                      <PrimaryLink
                        size="md"
                        text="Dont' use?"
                        onClickFunc={dontUseFunction}
                      />
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="sm:hidden block">{renderButton()}</div>
                  </div>
                </div>
              </div>
            </div>
            {gmbEmail && icon === "google" && (
              <div className="flex my-2">
                <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm-medium text-blue-800">
                  {gmbEmail}
                </span>
              </div>
            )}
            {fbPageName && icon === "facebook" && (
              <div className="flex my-2">
                <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm-medium text-blue-800">
                  {fbPageName}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sm:flex hidden flex-row items-center">
        <div className="sm:flex hidden">{renderButton()}</div>
      </div>
    </div>
  );
}
