import React, { useContext, useEffect, useState } from "react";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import useIsMobile from "../../../../hooks/useIsMobile";
import WidgetContainer from "../../dashboard/WidgetContainer/WidgetContainer";
import Link from "next/link";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import RequestAReviewModal from "../RequestAReviewModal/RequestAReviewModal";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { filterByRange } from "../ReviewContainer/utils";
import MonthlyGoalModal from "../../dashboard/MonthlyGoalModal/MonthlyGoalModal";
import ReviewContainer from "../ReviewContainer/ReviewContainer";
import { checkIfAlreadySentRequestReviewWithin6Months } from "../../contacts/ContactsPage/utils";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import { useAuthGoogle } from "../../../../hooks/useAuthGoogle";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

export default function ReviewsWidget() {
  const {
    profileInfo,
    allRequests,
    requestsCount,
    setRequestCount,
    isGmbAuthenticated,
    selectedContacts,
    setSelectedContacts,
    reviewRequestModalOpen,
    setReviewRequestModalOpen,
  } = useContext(DesktopContext);

  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();
  const { review_monthly_goal } = profileInfo || {};
  const isMobile = useIsMobile();
  const slug = profileInfo?.slug;
  const { startGoogleLogin } = useAuthGoogle(slug);

  const [monthlyGoalModalOpen, setMonthlyGoalModalOpen] = useState(false);
  const [monthlyGoal, setMonthlyGoal] = useState<any>(review_monthly_goal);
  const [monthlyGoalInteger, setMonthlyGoalInteger] = useState<any>(
    parseInt(monthlyGoal)
  );

  const [isEditingMonthlyGoal, setIsEditingMonthlyGoal] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    monthlyGoalModalOpen
      ? setIsEditingMonthlyGoal(true)
      : setIsEditingMonthlyGoal(false);
  }, [monthlyGoalModalOpen]);

  useEffect(() => {
    if (allRequests) {
      const reviewRequestsLast30Days = filterByRange(30, allRequests);
      const numberOfRequests = reviewRequestsLast30Days?.length;
      setRequestCount(numberOfRequests);
    }
  }, [allRequests]);

  useEffect(() => {
    const reviewRequestsLast30Days = filterByRange(30, allRequests);
    if (!isEditingMonthlyGoal) {
      setMonthlyGoalInteger(parseInt(monthlyGoal));
    }
  }, [monthlyGoal]);

  function openMonthlyGoalModal() {
    setMonthlyGoalModalOpen(true);
  }

  let badgeText;
  if (requestsCount >= monthlyGoalInteger) {
    badgeText = (
      <div className="sm:text-sm-normal text-xs-normal">
        Congrats! You&apos;ve reached your{" "}
        <span
          className="underline cursor-pointer"
          onClick={openMonthlyGoalModal}
        >
          monthly goal
        </span>
      </div>
    );
  } else {
    badgeText = (
      <div className="sm:text-sm-normal text-xs-normal">
        {monthlyGoalInteger - requestsCount} more requests to reach your{" "}
        <span
          className="underline cursor-pointer"
          onClick={openMonthlyGoalModal}
        >
          monthly goal
        </span>
      </div>
    );
  }

  function onMonthlyGoalChangeHandler(e: any) {
    const isNumeric = !isNaN(e.target.value);

    if (!monthlyGoal && e.target.value === "0") {
      return;
    }
    if (isNumeric) {
      setMonthlyGoal(e.target.value);
    }
  }

  function askForReviewsHandler() {
    if (shouldOpenChurnModal()) return;

    setReviewRequestModalOpen(true);
    let newCounter = counter;
    let invalidRecipientsVar: any = [];
    const goodSelectedContacts = selectedContacts.filter((contact) => {
      const bool = checkIfAlreadySentRequestReviewWithin6Months(
        new Date(contact?.date_request_review_sent)
      );
      if (bool) {
        newCounter += 1;
        invalidRecipientsVar = [...invalidRecipientsVar, contact.id];
      }
      return bool === false;
    });

    setCounter(newCounter);
    setSelectedContacts(goodSelectedContacts);
  }

  return (
    <>
      {renderChurnModal()}

      <MonthlyGoalModal
        monthlyGoalModalOpen={monthlyGoalModalOpen}
        setMonthlyGoalModalOpen={setMonthlyGoalModalOpen}
        monthlyGoal={monthlyGoal}
        setMonthlyGoal={setMonthlyGoal}
        onChangeHandler={onMonthlyGoalChangeHandler}
        setMonthlyGoalInteger={setMonthlyGoalInteger}
      />

      <RequestAReviewModal
        reviewRequestModalOpen={reviewRequestModalOpen}
        setReviewRequestModalOpen={setReviewRequestModalOpen}
        recipients={selectedContacts}
        setRecipients={setSelectedContacts}
      />

      <UnsubscribedEmptyStateWrapper>
        <WidgetContainer>
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-h2-bold text-gray-900">
                Google Reviews Leaderboard
              </h1>
            </div>
            <SecondaryButton
              size="md"
              text="Ask for reviews"
              onClickFunc={askForReviewsHandler}
              customStyle={`${isGmbAuthenticated ? "" : "hidden"}`}
            />
            <PrimaryButton
              size="md"
              text="Connect"
              onClickFunc={startGoogleLogin}
              customStyle={`${isGmbAuthenticated ? "hidden" : "self-start"}`}
            />
          </div>
          <ReviewContainer fromHomePage={true} />

          <div className="flex justify-center mt-3">
            <Link href="/reviews">
              <a>
                <PrimaryLink
                  size={isMobile ? "sm" : "md"}
                  text={`View all reviews`}
                  icon="right-arrow"
                  iconPlacement="lagging"
                  customStyle="font-medium"
                />
              </a>
            </Link>
          </div>
        </WidgetContainer>
      </UnsubscribedEmptyStateWrapper>
    </>
  );
}
