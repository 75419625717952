import { useRouter } from "next/router";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import UnsubscribedEmptyStateWrapper from "../../../common/UnsubscribedEmptyStateWrapper/UnsubscribedEmptyStateWrapper";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import ReferAFriendPopup from "../../refer/ReferAFriendPopup/ReferAFriendPopup";
import ReviewsWidget from "../../reviews/ReviewsWidget/ReviewsWidget";
import PhotoUploadWidget from "../PhotoUploadWidget/PhotoUploadWidget";
import { ShowcasesWidget } from "../ShowcasesWidget/ShowcasesWidget";
import StripePopup from "../StripePopup/StripePopup";
import IntercomWidget from "./IntercomWidget";
import {
  sendSafariNoti,
  triggerDownloadAppPopup,
  addHttp,
  firstLoginAfterPublish,
  triggerStripeIncentive,
  renderDomainLink,
  renderCopyButton,
  updateShowLiveWebsiteFacebookPopUp,
  getFilteredData,
  thirtyDaysAgo,
  toggleDigitalMediaPopup,
  togglePhysicalMarketingPopup,
} from "./utils";
import WelcomePopup from "../WelcomePopup/WelcomePopup";
import { addToHomeScreen } from "../../../../pages/_app";
import {
  detectChrome,
  detectIOS,
  detectSafari,
} from "../../../../helpers/promptUser";
import { ArrowNarrowDownIcon, XIcon } from "@heroicons/react/solid";
import ConnectionsScreen from "../ConnectionsScreen/ConnectionsScreen";
import SocialMediaWidget from "../SocialMediaWidget/SocialMediaWidget";
import PaymentsWidget from "../PaymentsWidget/PaymentsWidget";
import { checkStatus } from "../../payments/utils";
import CompleteSetupWidget from "../../reviews/CompleteSetupWidget/CompleteSetupWidget";
import UnSubscribedEmptyState from "../../../common/UnSubscribedEmptyState/UnSubscribedEmptyState";

import {
  formatDate,
  formatDateLong,
} from "../../reviews/ReviewContainer/utils";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useAuthGoogle } from "../../../../hooks/useAuthGoogle";

import ActionModal from "../../../common/ActionModal/ActionModal";
import AdsDashBoardWidget from "../../../AdsDashBoardWidget/AdsDashBoardWidget";
import Assistant from "../../../Assistant/Assistant";
import { IFacebookPageData, ISocialMediaPost } from "./types";
import GenericDropdown from "../../../common/GenericDropdown/GenericDropdown";
import InsightTile from "../../../uiwrappers/InsightTile/InsightTile";
import DigitalMediaPopup from "./DigitalMediaPopup";
import QuickActionTools from "../QuickActionTools/QuickActionTools";
import StatsCardWrapper from "../../../StatsCardWrapper/StatsCardWrapper";
import StatsCardSection from "../../../StatsCardSection/StatsCardSection";
import { useShowBanner } from "../../../../hooks/useShowBanner";
import { useContacts } from "../../../../hooks/useContacts/useContacts";
import { AdminContext } from "../../../../contexts/AdminDesktopNavigationContext/AdminDesktopNavigationContext";
import { PhysicalMarketingReleaseModal } from "../../marketing/PhysicalMarketing/ReleaseAssets/PhysicalMarketingReleaseModal";
import BlueTooltip from "../../../uiwrappers/BlueTooltip/BlueTooltip";
import AnalyticsWidget from "../../../AnalyticsWidget/AnalyticsWidget";
import { MobileSiteSelector } from "../MobileSiteSelector/MobileSiteSelector";
import { getReviewsWithoutShowcases } from "../../../ProjectShowcases/utils";

export default function DashboardPage(): ReactElement {
  const {
    profileInfo,
    basicInfo,
    setProfileInfo,
    facebookPageId,
    facebookPageToken,
    isGmbAuthenticated,
    isInstagramAuthenticated,
    gmbVerificationStatus,
    isFacebookAuthenticated,
    isQuickbooksAuthenticated,
    googleContactsAuthenticated,
    doesUseQuickbooks,
    userSchedulePosts,
    userFacebookPosts,
    userGMBPosts,
    userInstagramPosts,
    userFacebookFollowerCount,
    userFacebookLikes,
    userStripeAccount,
    allUserStripeInvoices,
    bookingRequests,
    analytics,
    setGoogleSearchKeyWords,
    setGscImpressions,
    googleImpressionsCount,
    setGoogleImpressionsCount,
    setNumberOfWeeks,
    loading,
    paymentFailureError,
    isLoginVerified,
    fetchWebsiteViews,
    showChatbotTooltip,
    setShowChatbotTooltip,
    allProjectShowcases,
    reviewInfo,
  } = useContext(DesktopContext);
  const reviewsWithNoShowcase = getReviewsWithoutShowcases(
    allProjectShowcases,
    reviewInfo
  );
  const { isToplineProAdminUser } = useContext(AdminContext);

  const {
    domain,
    slug,
    download_app_popup_shown,
    digital_media_popup_shown,
    dashboard_connections_screen_shown,
    first_login_time,
    stripe_connect_account_id,
    who_pays_stripe_fees,
    show_facebook_popup_for_live_site_announcement,
    physical_marketing_popup_shown,
  } = profileInfo || {};

  const {
    renderGmbUnverifiedBanner,
    renderGmbPendingBanner,
    renderGmbSuspendedBanner,
    renderFbExpiredBanner,
    renderDelinquentBanner,
    renderLegacyPricingBanner,
    renderChurnBanner,
    renderAdsPaymentFailureBanner,
    renderFbWaitlistBanner,
  } = useShowBanner();

  const [showConnectionsScreen, setShowConnectionsScreen] = useState(true);
  const [showReferFriendPopup, setShowReferFriendPopup] = useState(false);
  const [showFacebookPopup, setShowFacebookPopup] = useState(false);
  const [showStripePopup, setShowStripePopup] = useState(false);
  const [showDigitalMediaPopup, setShowDigitalMediaPopup] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [facebookPageData, setFacebookPageData] = useState<IFacebookPageData>(
    {} as IFacebookPageData
  );
  const [userAgent, setUserAgent] = useState("");
  const router = useRouter();
  const [feedArray, setFeedArray] = useState<ISocialMediaPost[]>([]);
  const [isFeedFetched, setIsFeedFetched] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [openInvoices, setOpenInvoices] = useState<any[]>([]);
  const [paidInvoices, setPaidInvoices] = useState<any[]>([]);
  const [allPayments, setAllPayments] = useState<any[]>([]);
  const [showCompleteSetupWidget, setShowCompleteSetupWidget] =
    useState<boolean>(false);

  const [showGalleryModal, setShowGalleryModal] = useState<boolean>(false);
  const [createPostModalOpen, setCreatePostModalOpen] = useState(false);

  const [bookingsData, setBookingsData] = useState<any>();
  const [totalRequests, setTotalRequests] = useState<any>();
  const [requestPaymentModalOpen, setRequestPaymentModalOpen] =
    useState<boolean>(false);
  const { startGoogleLogin } = useAuthGoogle(slug);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [timePeriod, setTimePeriod] = useState("Last 30 days");
  const [googleImpressionsData, setGoogleImpressionsData] = useState([]);
  const [googleCallData, setGoogleCallData] = useState([]);
  const [formattedTimePeriod, setFormattedTimePeriod] = useState<string>("");
  const [formattedLongTimePeriod, setFormattedLongTimePeriod] =
    useState<string>("");
  const [focusSite, setFocusSite] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const mobileSiteRef = useRef<HTMLAnchorElement | null>(null);

  const [isProcessingImages, setIsProcessingImages] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [websiteViews, setWebsiteViews] = useState<any>();
  const [currentWebsiteView, setCurrentWebsiteView] = useState<number>();

  const today = new Date();
  const getWebsiteViewForTimePeriod = () => {
    switch (timePeriod) {
      case "Today":
        setCurrentWebsiteView(websiteViews.today_views);
        break;
      case "Last 7 days":
        setCurrentWebsiteView(websiteViews.seven_day_views);
        break;
      case "Last 30 days":
        setCurrentWebsiteView(websiteViews.thirty_day_views);
        break;
      case "Last 60 days":
        setCurrentWebsiteView(websiteViews.sixty_day_views);
        break;
      case "Last 90 days":
        setCurrentWebsiteView(websiteViews.ninety_day_views);
        break;
      case "Last year":
        setCurrentWebsiteView(websiteViews.year_views);
        break;
      default:
        break;
    }
  };
  const setNumberOfWeeksForTimePeriod = () => {
    switch (timePeriod) {
      case "Today":
        setNumberOfWeeks(1);
        break;
      case "Last 7 days":
        setNumberOfWeeks(1);
        break;
      case "Last 30 days":
        setNumberOfWeeks(4);
        break;
      case "Last 60 days":
        setNumberOfWeeks(60 / 7);
        break;
      case "Last 90 days":
        setNumberOfWeeks(90 / 7);
        break;
      case "Last year":
        setNumberOfWeeks(52);
        break;
      default:
        break;
    }
  };

  const { renderCsvModal } = useContacts();
  const [showPhysicalMarketingPopup, setShowPhysicalMarketingPopup] =
    useState<boolean>(false);

  useEffect(() => {
    if (router.query.focusSite) {
      setFocusSite(true);
      if (mobileSiteRef?.current) {
        mobileSiteRef.current.scrollIntoView({
          block: "end",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    } else {
      setFocusSite(false);
    }
  }, [router.query.focusSite]);

  useEffect(() => {
    if (basicInfo?.fb_tokens_expired) {
      setShowCompleteSetupWidget(true);
      return;
    }

    if (
      !isFacebookAuthenticated ||
      !isGmbAuthenticated ||
      (!isQuickbooksAuthenticated &&
        doesUseQuickbooks !== "no" &&
        !googleContactsAuthenticated)
    ) {
      setShowCompleteSetupWidget(true);
    } else {
      setShowCompleteSetupWidget(false);
    }
  }, [
    isFacebookAuthenticated,
    isGmbAuthenticated,
    isQuickbooksAuthenticated,
    basicInfo?.fb_tokens_expired,
    googleContactsAuthenticated,
  ]);

  useEffect(() => {
    fetchWebsiteViews(profileInfo?.slug).then((views: any) =>
      setWebsiteViews(views?.message)
    );

    const firstLoginTenDaysAgo = firstLoginXDaysAgo(10);
    const firstLoginThirtyDaysAgo = firstLoginXDaysAgo(30);

    if (firstLoginTenDaysAgo) {
      setShowConnectionsScreen(false);
    } else {
      if (profileInfo && !dashboard_connections_screen_shown) {
        if (
          !isFacebookAuthenticated ||
          !isGmbAuthenticated ||
          !isInstagramAuthenticated ||
          (!isQuickbooksAuthenticated &&
            doesUseQuickbooks !== "no" &&
            !googleContactsAuthenticated)
        ) {
          setShowConnectionsScreen(true);
          setProfileInfo({
            ...profileInfo,
            dashboard_connections_screen_shown: true,
          });
          return;
        }
      }
    }

    // if first time login after site is live
    if (
      !profileInfo?.first_login_after_publish &&
      profileInfo?.sent_initial_zapier
    ) {
      firstLoginAfterPublish(profileInfo?.slug);
      setShowFacebookPopup(true);
      setProfileInfo({
        ...profileInfo,
        first_login_after_publish: true,
      });
      return;
    }

    // if first login > 30 days ago and show_facebook_popup_for_live_site is still true in database, update this field to false. From then, do not show pop up again.
    else if (
      firstLoginThirtyDaysAgo &&
      show_facebook_popup_for_live_site_announcement &&
      profileInfo
    ) {
      updateShowLiveWebsiteFacebookPopUp(slug);
      return;
    }

    // if show_facebook_popup_for_live_site is true, show popup
    else if (
      show_facebook_popup_for_live_site_announcement &&
      profileInfo &&
      profileInfo?.first_login_after_publish
    ) {
      setStep(2);
      setShowFacebookPopup(true);
      return;
    }

    if (
      router?.query?.welcome &&
      (profileInfo?.first_login_after_publish ||
        !profileInfo?.sent_initial_zapier)
    ) {
      setShowReferFriendPopup(true);
      return;
    }

    const stripePopup = triggerStripePopup();
    if (stripePopup) {
      triggerStripeIncentive();
      setShowStripePopup(true);
      if (profileInfo) {
        setProfileInfo({
          ...profileInfo,
          stripe_incentive_triggered: true,
        });
      }
      return;
    }

    if (
      !digital_media_popup_shown &&
      profileInfo?.user_type !== "Topline Admin"
    ) {
      setShowDigitalMediaPopup(true);
      toggleDigitalMediaPopup(slug);
    }
  }, []);

  useEffect(() => {
    if (!download_app_popup_shown && !showConnectionsScreen) {
      const user = getUserAgent();
      setUserAgent(user);
      if (isMobile && profileInfo && !showStripePopup) {
        setShowDownloadPopup(true);
        triggerDownloadAppPopup();
        setProfileInfo({
          ...profileInfo,
          download_app_popup_shown: true,
        });
      }
    }
  }, [isMobile, profileInfo, showConnectionsScreen, showStripePopup]);

  useEffect(() => {
    if (
      isFacebookAuthenticated &&
      isGmbAuthenticated &&
      // isInstagramAuthenticated &&
      (isQuickbooksAuthenticated ||
        doesUseQuickbooks === "yes" ||
        googleContactsAuthenticated)
    ) {
      setShowConnectionsScreen(false);
    }
  }, [
    isFacebookAuthenticated,
    isGmbAuthenticated,
    isQuickbooksAuthenticated,
    doesUseQuickbooks,
    googleContactsAuthenticated,
  ]);

  useEffect(() => {
    async function fetchSocialData() {
      try {
        initialPostsPull();
        const totalFollowers = userFacebookFollowerCount;
        const totalLikes = userFacebookLikes;
        const fbData = { ...totalFollowers, ...totalLikes };
        setFacebookPageData(fbData);
      } catch (e) {
        console.log("error:", e);
      }
    }

    if (
      isFacebookAuthenticated ||
      isGmbAuthenticated ||
      isInstagramAuthenticated
    )
      fetchSocialData();
  }, [
    userFacebookFollowerCount,
    userFacebookLikes,
    isFacebookAuthenticated,
    isGmbAuthenticated,
    userFacebookPosts,
    userGMBPosts,
    userInstagramPosts,
  ]);

  useEffect(() => {
    function fetchStripeData() {
      if (stripe_connect_account_id) {
        const stripeAccountInfo = userStripeAccount;
        const status = checkStatus(stripeAccountInfo, who_pays_stripe_fees);
        setStatus(status);
        if (
          status === "complete" ||
          status === "enabled" ||
          status === "restricted soon" ||
          status === "restricted"
        ) {
          const allInvoices = allUserStripeInvoices;

          const open = allInvoices?.open?.data.filter(
            (i: any) => i.amount_due !== 0
          );
          const paid = allInvoices?.paid?.data.filter(
            (i: any) => i.amount_paid !== 0
          );
          const allPayments = allInvoices?.allPayments?.data;
          setOpenInvoices(open);
          setPaidInvoices(paid);
          setAllPayments(allPayments);
        }
      } else {
        setStatus("");
      }
    }

    fetchStripeData();
  }, [userStripeAccount, allUserStripeInvoices, stripe_connect_account_id]);

  useEffect(() => {
    async function fetchGoogleAnalytics() {
      if (analytics && analytics.length > 0) {
        const impressionsData = [];
        const callData = [];

        for (let i = 0; i <= 3; i++) {
          try {
            const data = analytics[i].data.timeSeries.datedValues;
            impressionsData.push(data);
          } catch (e) {
            console.log("error:", e);
          }
        }
        setGoogleImpressionsData(impressionsData);

        const data = analytics[4].data.timeSeries.datedValues;
        callData.push(data);
        setGoogleCallData(callData);

        const latestKeyWords = new Map<string, number>();
        const searchKeyWordData = analytics[5];

        if (searchKeyWordData?.data?.searchKeywordCounts) {
          searchKeyWordData.data.searchKeywordCounts.map((keyword) => {
            latestKeyWords.set(
              keyword.searchKeyword,
              keyword.insightsValue.value ? keyword.insightsValue.value : 14
            );
          });
          setGoogleSearchKeyWords(latestKeyWords);
        }

        const formattedDate = `${formatDate(thirtyDaysAgo)} - ${formatDate(
          today
        )}`;
        setFormattedTimePeriod(formattedDate);
        const formattedDateLong = `${formatDateLong(
          thirtyDaysAgo
        )} - ${formatDateLong(today)}`;
        setFormattedLongTimePeriod(formattedDateLong);
      }
    }

    fetchGoogleAnalytics();
  }, [analytics]);

  useEffect(() => {
    calculateAnalytics(timePeriod);
  }, [googleImpressionsData, bookingRequests]);

  useEffect(() => {
    if (websiteViews) {
      getWebsiteViewForTimePeriod();
    }
    setNumberOfWeeksForTimePeriod();
  }, [timePeriod, websiteViews]);

  useEffect(() => {
    if (!physical_marketing_popup_shown && isToplineProAdminUser) {
      setShowPhysicalMarketingPopup(true);
      togglePhysicalMarketingPopup(slug);

      setProfileInfo({
        ...profileInfo,
        physical_marketing_popup_shown: true,
      });
    }
  }, []);

  useEffect(() => {
    const chatbotTooltip = localStorage.getItem("chatbotTooltip");
    if (!chatbotTooltip && !showConnectionsScreen) {
      setShowChatbotTooltip(true);
      localStorage.setItem("chatbotTooltip", "shown");
    }
  }, [showConnectionsScreen]);

  function redirectReferrals() {
    router.push("/settings/refer-a-friend");
  }

  function getTotalImpressions(impressions) {
    if (impressions.length > 0) {
      let totalImpressions: any = 0;

      try {
        impressions?.forEach((date: any) => {
          if (date?.value) {
            totalImpressions += parseInt(date.value);
          }
        });
      } catch (e) {
        totalImpressions = "N/A";
      }

      return totalImpressions;
    } else {
      return "";
    }
  }

  function getTotalCalls(calls) {
    if (calls.length > 0) {
      let callClicks: any = 0;

      try {
        calls.forEach((date: any) => {
          if (date?.value) {
            callClicks += parseInt(date.value);
          }
        });
      } catch (e) {
        callClicks = "N/A";
      }

      return callClicks;
    } else {
      return "";
    }
  }

  //Impressions data are actually 4 lists combined together: desktop Maps, desktop search, mobile maps, mobile search
  //This results in 4 duplicate date keys in the array
  const combineImpressionsData = (
    impressionsData: {
      date: { year: number; month: number; day: number };
      value: string;
    }[]
  ): void => {
    // We want to use a map instead of a JSON object because:
    // A Map object iterates entries, keys, and values in the order of entry insertion.
    const impressionMap = new Map();
    impressionsData.map((data) => {
      //The Date key needs to be in the form of YEAR-MO-DA
      const key = new Date(
        data.date.year,
        data.date.month - 1,
        data.date.day
      ).toDateString();
      const currentValue = impressionMap.get(key);
      const newValue = data.value ? parseInt(data.value) : 0;
      currentValue
        ? impressionMap.set(key, currentValue + newValue)
        : impressionMap.set(key, newValue);
    });
    setGscImpressions(impressionMap);
  };

  function calculateAnalytics(time: string) {
    setTimePeriod(time);

    const {
      filteredImpressionsArray,
      filteredCallsArray,
      filteredBookingsArray,
      formattedDate,
      formattedDateLong,
    } = getFilteredData(
      time,
      googleImpressionsData,
      googleCallData,
      bookingRequests
    );

    const totalImpressions = getTotalImpressions(filteredImpressionsArray);
    combineImpressionsData(filteredImpressionsArray);

    const totalCalls = getTotalCalls(filteredCallsArray);
    const totalBookings = filteredBookingsArray?.length;

    setGoogleImpressionsCount(totalImpressions);
    setBookingsData(totalBookings);
    setTotalRequests(totalBookings + totalCalls);

    setFormattedTimePeriod(formattedDate);
    setFormattedLongTimePeriod(formattedDateLong);
  }

  function getUserAgent() {
    const iOS = detectIOS();
    if (iOS) {
      const chrome = detectChrome();
      const safari = detectSafari();
      if (chrome) {
        return "Apple - Chrome";
      } else if (safari) {
        return "Apple - Safari";
      } else {
        return "Apple - Other";
      }
    } else {
      return "Android";
    }
  }

  function triggerStripePopup() {
    if (
      profileInfo?.first_login_after_publish &&
      profileInfo?.total_login_count === 1 &&
      !profileInfo?.stripe_incentive_triggered
    ) {
      return true;
    }

    return false;
  }

  const domainLink = addHttp(domain);

  function hideDownloadPopup() {
    setShowDownloadPopup(false);
  }

  async function sendSafariNotification() {
    await sendSafariNoti();
    setShowDownloadPopup(false);
  }

  function initialPostsPull() {
    let fbFeedArray = [];
    const fbFeedTextContent = {} as any; // cache object to compare google posts to and ultimately remove dupes
    if (isFacebookAuthenticated) {
      try {
        const response = userFacebookPosts;
        fbFeedArray =
          response?.data?.map((post) => {
            if (!post.message) {
              post.message = "";
            } else {
              fbFeedTextContent[post.message] = post.created_time;
            }
            if (!post.full_picture) {
              post.full_picture = "";
            }
            return { ...post, origin: { facebook: post.id } };
          }) || [];
      } catch (e) {
        console.log("error fetching facebook feed");
      }
    }

    const formattedGmbPosts = [];
    const dupeHash = {} as any;

    if (isGmbAuthenticated) {
      try {
        const gmbPosts = userGMBPosts;
        if (gmbPosts && gmbPosts.length > 0) {
          for (let i = 0; i < gmbPosts.length; i++) {
            if (
              !fbFeedTextContent[gmbPosts[i].summary] &&
              gmbPosts[i].state !== "REJECTED"
            ) {
              formattedGmbPosts.push({
                created_time: gmbPosts[i].createTime,
                full_picture:
                  gmbPosts[i].media && gmbPosts[i].media[0].googleUrl,
                id: gmbPosts[i].name, // this has the mediaKey after the accountId/locationId
                message: gmbPosts[i].summary,
                origin: { google: gmbPosts[i].name },
                state: gmbPosts[i].state,
              });
            } else {
              dupeHash[gmbPosts[i].summary] = {
                created_time: gmbPosts[i].createTime,
                google_id: gmbPosts[i].name,
              };
            }
          }
        }
      } catch (e) {
        console.log(e, "error fetching google feed");
      }
    }

    let allPosts = [];
    const igPosts = isInstagramAuthenticated ? userInstagramPosts : [];
    allPosts = [...formattedGmbPosts, ...fbFeedArray, ...igPosts];

    const allFilteredPosts = allPosts.map((post, idx) => {
      let finalPost = { ...post };
      if (dupeHash[post.message]) {
        finalPost = {
          ...post,
          origin: {
            google: dupeHash[post.message].google_id,
            facebook: post.origin.facebook,
          },
        };
      }
      return finalPost;
    });
    const sortedPosts = allFilteredPosts.sort(function (x, y) {
      const xDate: any = new Date(x.created_time);
      const yDate: any = new Date(y.created_time);
      return yDate - xDate;
    });
    setFeedArray(sortedPosts);
    setIsFeedFetched(true);
    return sortedPosts;
  }

  function firstLoginXDaysAgo(numberOfDays: number) {
    if (first_login_time) {
      const now: any = new Date();
      const firstLoginDate: any = new Date(first_login_time);
      const diff = now - firstLoginDate;
      const diffDays = diff / (1000 * 60 * 60 * 24);

      if (diffDays > numberOfDays) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const timePeriods = [
    { text: "Today", onClick: () => calculateAnalytics("Today") },
    { text: "Last 7 days", onClick: () => calculateAnalytics("Last 7 days") },
    { text: "Last 30 days", onClick: () => calculateAnalytics("Last 30 days") },
    { text: "Last 90 days", onClick: () => calculateAnalytics("Last 90 days") },
    { text: "Last year", onClick: () => calculateAnalytics("Last year") },
  ];

  if (profileInfo && !profileInfo?.slug) {
    return <UnSubscribedEmptyState />;
  }

  if (showConnectionsScreen) {
    return (
      <ConnectionsScreen
        showConnectionsScreen={showConnectionsScreen}
        setShowConnectionsScreen={setShowConnectionsScreen}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col justify-start flex-grow w-full min-h-full h-fit sm:py-4 pb-24 md:space-y-0">
        <div className="flex justify-between bg-white sm:bg-gray-50 items-center px-4 sm:px-8 w-full ">
          <div className="relative w-full">
            <Assistant />

            <BlueTooltip
              isOpen={showChatbotTooltip}
              setOpen={setShowChatbotTooltip}
              header="Topline Pro Assistant"
              subheader="Complete tasks, find answers, brainstorm ideas. Just ask."
              arrowDirection="triangle-up"
              arrowPosition={{
                top: -8,
                left: "50%",
                transform: "translateX(-50%)",
              }}
              relativePosition={
                isMobile
                  ? { left: "50%", transform: "translateX(-50%)", top: 80 }
                  : { left: "22.5%", transform: "translateX(-50%)", top: 64 }
              }
            />
          </div>

          <div className="space-x-3 hidden  md:flex lg:items-center">
            <div className="gap-3 hidden lg:flex lg:justify-end lg:items-center lg:w-full">
              {isGmbAuthenticated && (
                <a
                  className={`flex ${domain ? "w-auto" : ""}`}
                  target="_blank"
                  href="https://business.google.com/locations"
                  rel="noreferrer"
                >
                  <SecondaryButton
                    size={"md"}
                    icon="external-link"
                    text=""
                    iconPlacement="lagging"
                    mainIcon={"Google"}
                  />
                </a>
              )}
              {isFacebookAuthenticated && (
                <a
                  className={`flex ${domain ? "w-auto" : ""}`}
                  target="_blank"
                  href={`https://www.facebook.com/${facebookPageId}`}
                  rel="noreferrer"
                >
                  <SecondaryButton
                    size={"md"}
                    icon="external-link"
                    text=""
                    iconPlacement="lagging"
                    mainIcon="Facebook"
                    customStyle="w-auto"
                  />
                </a>
              )}
              {!!domain && (
                <>
                  {renderDomainLink(
                    domainLink,
                    domain,
                    focusSite ? "border-blue-500 ring-8 animate-bounce-6" : "",
                    "md"
                  )}
                  {renderCopyButton(domain)}
                </>
              )}
            </div>
          </div>
        </div>
        {!loading && isLoginVerified && isMobile && (
          <div className="sticky top-0 z-10">
            {renderChurnBanner()}
            {renderGmbUnverifiedBanner()}
            {renderGmbPendingBanner()}
            {renderGmbSuspendedBanner()}
            {renderFbExpiredBanner()}
            {renderLegacyPricingBanner()}
            {paymentFailureError && renderDelinquentBanner()}
            {renderAdsPaymentFailureBanner()}
            {renderFbWaitlistBanner()}
          </div>
        )}
        <div className="px-4  flex bg-gray-200 sm:hidden">
          <QuickActionTools
            setCreatePostModalOpen={setCreatePostModalOpen}
            setShowGalleryModal={setShowGalleryModal}
            setImages={setImages}
            setFiles={setFiles}
            isProcessingImages={isProcessingImages}
            setIsProcessingImages={setIsProcessingImages}
          />
        </div>

        <MobileSiteSelector></MobileSiteSelector>

        <div className="px-4 mt-4">
          <div className="bg-green-600 flex justify-between items-center text-sm-semibold px-3 py-2.5 text-white sm:hidden rounded-lg whitespace-nowrap">
            <div>1 Referral = $200 Reward</div>
            <SecondaryButton
              text="Refer, Get Paid 🤑"
              size="xs"
              onClickFunc={redirectReferrals}
              customStyle="whitespace-nowrap"
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col sm:justify-between pl-4">
          <div className="flex gap-3 sm:justify-center justify-start items-center overflow-x-auto no-scrollbar pb-2 pt-5  pr-4 sm:pr-8 sm:ml-4  lg:hidden">
            <>
              {isGmbAuthenticated && (
                <a
                  className={`flex ${domain ? "w-auto" : ""}`}
                  target="_blank"
                  href="https://business.google.com/locations"
                  rel="noreferrer"
                >
                  <SecondaryButton
                    size={"md"}
                    icon="external-link"
                    text=""
                    iconPlacement="lagging"
                    mainIcon={"Google"}
                    customStyle="px-4"
                  />
                </a>
              )}
              {isFacebookAuthenticated && (
                <a
                  className={`flex ${domain ? "w-auto" : ""}`}
                  target="_blank"
                  href={`https://www.facebook.com/${facebookPageId}`}
                  rel="noreferrer"
                >
                  <SecondaryButton
                    size={"md"}
                    icon="external-link"
                    text=""
                    iconPlacement="lagging"
                    mainIcon="Facebook"
                    customStyle="w-auto"
                  />
                </a>
              )}
              {!!domain && (
                <>
                  {renderDomainLink(
                    domainLink,
                    domain,
                    focusSite ? "border-blue-500 ring-8 animate-bounce" : "",
                    "md"
                  )}
                  {renderCopyButton(domain, mobileSiteRef)}
                </>
              )}
            </>
          </div>
        </div>
        <div className="px-4 pt-4 md:px-8">
          <UnsubscribedEmptyStateWrapper>
            <>
              <div>
                <div className="flex items-center justify-between sm:justify-start">
                  <div className="mr-6">
                    <GenericDropdown
                      displayedItem={{ text: timePeriod }}
                      dropdownItems={timePeriods}
                      menuButton={false}
                      menuButtonIcon="calendar"
                      dropDirection="bottom-start"
                    />
                  </div>
                  <div className="flex my-2 items-center">
                    <div className="text-sm-normal text-gray-500">
                      {formattedTimePeriod}
                    </div>
                  </div>
                </div>

                <div className="sm:flex flex-row justify-start items-center overflow-x-auto no-scrollbar -ml-1 hidden ">
                  <InsightTile
                    title="Website Views"
                    icon="desktop-computer"
                    data={currentWebsiteView}
                    customStyle="my-1 mr-3 sm:mr-4"
                    hideIcon={isMobile}
                    tooltipText="The number of views on your Landing page"
                  />
                  <InsightTile
                    title="Google Views"
                    icon="eye"
                    data={
                      isGmbAuthenticated
                        ? gmbVerificationStatus !== "verified"
                          ? "--"
                          : googleImpressionsCount
                        : "connect"
                    }
                    customStyle="my-1 mr-3 sm:mr-4"
                    customFunction={startGoogleLogin}
                    hideIcon={isMobile}
                    tooltipText="The number of views on your Google Business profile"
                  />
                  <InsightTile
                    title="Total Requests"
                    icon="cursor-click"
                    data={totalRequests}
                    customStyle="my-1 mr-3 sm:mr-4"
                    hideIcon={isMobile}
                    tooltipText="The number of form submissions and calls from your website and Google Business profile"
                  />
                  <InsightTile
                    title="Text Requests"
                    icon="trending-up"
                    data={bookingsData}
                    customStyle="my-1"
                    hideIcon={isMobile}
                    tooltipText="The number of form submissions through your website"
                  />
                </div>
                <div className="flex flex-row  xl:flex-wrap  justify-start items-center shadow rounded-lg  overflow-x-auto no-scrollbar -mr-4 sm:hidden ">
                  <StatsCardWrapper>
                    <StatsCardSection
                      title="Website Views"
                      tooltipText="The number of views on your Landing page"
                      content={
                        <div className="text-gray-900 text-3xl font-semibold font-['Inter'] leading-9">
                          {currentWebsiteView}
                        </div>
                      }
                    />
                    <div className="border h-full" />
                    <StatsCardSection
                      title="Google Views"
                      tooltipText="The number of views on your Google Business profile"
                      content={
                        <>
                          {isGmbAuthenticated &&
                            gmbVerificationStatus === "verified" && (
                              <div className="text-gray-900 text-3xl font-semibold font-['Inter'] leading-9">
                                {googleImpressionsCount}
                              </div>
                            )}

                          {isGmbAuthenticated &&
                            gmbVerificationStatus !== "verified" && (
                              <div className="text-gray-900 text-3xl font-semibold font-['Inter'] leading-9">
                                --
                              </div>
                            )}

                          {!isGmbAuthenticated && (
                            <div className="h-6  justify-start items-start gap-1 inline-flex">
                              <div
                                className="text-blue-600 text-base font-medium font-['Inter'] leading-normal"
                                onClick={startGoogleLogin}
                              >
                                Connect Google
                              </div>
                            </div>
                          )}
                        </>
                      }
                    />
                    <div className="border h-full" />
                    <StatsCardSection
                      title="Total Requests"
                      tooltipText="The number of form submissions and calls from your website and Google Business profile"
                      content={
                        <div className="text-gray-900 text-3xl font-semibold font-['Inter'] leading-9">
                          {totalRequests}
                        </div>
                      }
                    />
                    <div className="border h-full" />
                    <StatsCardSection
                      title="Text Requests"
                      tooltipText="The number of form submissions through your website"
                      content={
                        <div className="text-gray-900 text-3xl font-semibold font-['Inter'] leading-9">
                          {bookingsData}
                        </div>
                      }
                    />
                  </StatsCardWrapper>
                </div>
              </div>
              <div className={`w-full flex-grow sm:pr-4 xl:pr-0 max-w-7xl`}>
                <AnalyticsWidget
                  formattedLongTimePeriod={formattedLongTimePeriod}
                />
              </div>
              {/* <AnalyticsCharts /> */}
              <div className="xl:grid xl:grid-cols-2 py-2 sm:py-0">
                <div className="flex flex-col h-auto w-full flex-grow sm:pr-4">
                  {showCompleteSetupWidget && <CompleteSetupWidget />}

                  <ReviewsWidget />
                  <ShowcasesWidget
                    reviewsWithoutShowcases={reviewsWithNoShowcase}
                  />
                  <PhotoUploadWidget
                    showGalleryModal={showGalleryModal}
                    setShowGalleryModal={setShowGalleryModal}
                    images={images}
                    setImages={setImages}
                    files={files}
                    setFiles={setFiles}
                    isProcessingImages={isProcessingImages}
                    setIsProcessingImages={setIsProcessingImages}
                  />
                </div>
                <div className="flex flex-col h-auto w-full">
                  {(isFacebookAuthenticated || isGmbAuthenticated) && (
                    <SocialMediaWidget
                      scheduledPosts={userSchedulePosts || []}
                      allPosts={feedArray}
                      facebookPageData={facebookPageData || {}}
                      createPostModalOpen={createPostModalOpen}
                      setCreatePostModalOpen={setCreatePostModalOpen}
                    />
                  )}
                  <AdsDashBoardWidget />
                  <PaymentsWidget
                    stripeAccountId={stripe_connect_account_id}
                    status={status}
                    openInvoices={openInvoices || []}
                    setOpenInvoices={setOpenInvoices}
                    paidInvoices={paidInvoices || []}
                    allPayments={allPayments || []}
                    requestPaymentModalOpen={requestPaymentModalOpen}
                    setRequestPaymentModalOpen={setRequestPaymentModalOpen}
                  />
                </div>
              </div>
            </>
          </UnsubscribedEmptyStateWrapper>
        </div>

        <PhysicalMarketingReleaseModal
          isOpen={showPhysicalMarketingPopup}
          setOpen={setShowPhysicalMarketingPopup}
        />

        <ActionModal
          open={congratsModalOpen}
          setOpen={setCongratsModalOpen}
          noButtons={true}
          primaryButtonText=""
          confetti={true}
        >
          <div className="h-full">
            <div className="w-full h-full h-min-250 flex flex-col justify-center items-center">
              <div className="flex flex-shrink-0 relative h-12 w-12 object-cover items-center justify-center z-50">
                <Image layout={"fill"} src="/check-badge-icon.svg" alt="logo" />
              </div>

              <div className="text-gray-900 text-h2-medium mt-2 z-50">
                Congratulations!
              </div>
              <div className="text-gray-500 text-sm-normal mt-2 z-50">
                You met your request goal for the month.
              </div>
            </div>
          </div>
        </ActionModal>
        {userAgent === "Android" && showDownloadPopup && (
          <div
            className="sm:hidden flex flex-row justify-between bg-white shadow-2xl rounded-lg px-6 py-8 fixed left-0 bottom-20 right-0 m-4 z-50 text-sm"
            onClick={addToHomeScreen}
          >
            <div className="flex text-body-medium text-gray-500">
              Download the app
            </div>
            <div className="flex text-body-medium text-blue-600">Download</div>
          </div>
        )}
        {userAgent === "Apple - Safari" && showDownloadPopup && (
          <div className="sm:hidden flex flex-col justify-between bg-white shadow-2xl rounded-lg px-6 pb-4 pt-6 fixed left-0 bottom-0 right-0 m-4 z-50 text-sm">
            <XIcon
              onClick={hideDownloadPopup}
              className="w-5 h-5 text-gray-500 absolute right-4 top-4"
            />
            <div className="flex text-body-medium text-gray-500">
              Download the app
            </div>
            <div className="flex text-body-medium flex-row pt-0.5">
              <div className="flex text-gray-500 mr-2">Click</div>
              <svg
                width={15}
                height={15}
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 5L10 5C10.5523 5 11 5.44772 11 6V13C11 13.5523 10.5523 14 10 14L2 14C1.44772 14 1 13.5523 1 13V6C1 5.44771 1.44772 5 2 5H4"
                  stroke="#6B7280"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.5 3L6 0.5M6 0.5L8.5 3M6 0.5V9"
                  stroke="#6B7280"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-gray-500 ml-2">
                and “Add to Home Screen”
              </div>
            </div>
            <div className="flex justify-center text-center">
              <ArrowNarrowDownIcon className="w-5 h-5 text-blue-600 mt-2" />
            </div>
          </div>
        )}
        {userAgent === "Apple - Chrome" && showDownloadPopup && (
          <div className="sm:hidden flex flex-row justify-between bg-white shadow-2xl rounded-lg px-6 py-8 fixed left-0 bottom-20 right-0 m-4 z-50 text-sm">
            <div className="flex text-body-medium text-gray-500">
              Download the app
            </div>
            <div
              onClick={sendSafariNotification}
              className="flex text-body-medium text-blue-600"
            >
              Text Me
            </div>
          </div>
        )}

        {showReferFriendPopup && <ReferAFriendPopup />}
        {showFacebookPopup && (
          <WelcomePopup
            slug={slug || ""}
            domain={domain || ""}
            facebookPageToken={facebookPageToken}
            facebookPageId={facebookPageId}
            setShowFacebookPopup={setShowFacebookPopup}
            step={step}
            setStep={setStep}
          />
        )}
        {showStripePopup && <StripePopup />}

        <DigitalMediaPopup
          open={showDigitalMediaPopup}
          setOpen={setShowDigitalMediaPopup}
          setShowDigitalMediaPopup={setShowDigitalMediaPopup}
        />
        {renderCsvModal()}
        {process.env.NEXT_PUBLIC_SHOW_INTERCOM === "true" && <IntercomWidget />}
      </div>
    </>
  );
}
