import { useRouter } from "next/router";
import ActionModal from "../../../common/ActionModal/ActionModal";
import { useContext } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { getOneRandomTopic } from "../../marketing/DigitalMedia/utils";

export default function DigitalMediaPopup({
  open,
  setOpen,
  setShowDigitalMediaPopup,
}: any) {
  const gifPath = "/post_with_digital_media.gif";
  const router = useRouter();

  const {
    userPhoto,
    reviewInfo,
    userPersonalBlogs,
    userScheduleBlogs,
    basicInfo,
  } = useContext(DesktopContext);

  function startGenerateMedia() {
    const topic = getOneRandomTopic(
      userPhoto,
      reviewInfo,
      userPersonalBlogs,
      userScheduleBlogs,
      basicInfo
    );

    const origin = "home";
    router.push({
      pathname: "/marketing/digital-media-create",
      query: {
        origin,
        topic,
      },
    });

    setShowDigitalMediaPopup(false);
  }

  return (
    <ActionModal
      header="Boosting your online presence just got easier"
      headerSize="large"
      subheader="Instantly generate fully custom content for social media"
      open={open}
      setOpen={setOpen}
      secondaryButtonText="No Thanks"
      secondaryButtonFunction={() => setOpen(false)}
      primaryButtonText="Generate Media"
      primaryButtonIcon="generateMedia"
      primaryButtonIconPlacement="leading"
      primaryButtonFunction={startGenerateMedia}
      heroImage={gifPath}
    />
  );
}
