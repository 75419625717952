import { InformationCircleIcon } from "@heroicons/react/outline";
import React from "react";

interface StatSectionProps {
  title: string;
  content: React.ReactNode;
  gap?: string;
  additionalClasses?: string;
  tooltipText?: string;
}

const StatsCardSection: React.FC<StatSectionProps> = ({
  title,
  content,
  gap = "gap-2",
  additionalClasses = "",
  tooltipText = "",
}) => {
  return (
    <div
      className={`self-stretch flex-col justify-center items-start ${gap} inline-flex ${additionalClasses}`}
    >
      <div className="justify-center items-center gap-1 inline-flex">
        <div className="text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
          {title}
        </div>
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content={tooltipText}
          data-tooltip-place="top"
        >
          {tooltipText && (
            <InformationCircleIcon
              className="flex w-5 h-5 text-gray-500 cursor-pointer"
              style={{ strokeWidth: 1.5 }}
            />
          )}
        </a>
      </div>
      <div className="w-32 flex-col justify-center items-start flex">
        {content}
      </div>
    </div>
  );
};

export default StatsCardSection;
