import WidgetContainer from "../primaryrender/dashboard/WidgetContainer/WidgetContainer";
import WidgetHeader from "../WidgetHeader/WidgetHeader";
import WidgetHeading from "../WidgetHeading/WidgetHeading";
import WidgetBody from "../WidgetBody/WidgetBody";
import WidgetFooter from "../WidgetFooter/WidgetFooter";

const AdsWidgetLoader: React.FC = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>
        <WidgetHeading>Marketing</WidgetHeading>
        <div className="flex flex-col py-8 px-4 w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-row-reverse  w-full h-12 mb-4">
              <div className="animate-pulse bg-gray-200 shadow rounded-md w-24 ml-4"></div>
              <div className="animate-pulse bg-gray-200 shadow rounded-md w-24"></div>
            </div>
          </div>
        </div>
      </WidgetHeader>
      <WidgetBody>
        <div className="flex flex-row  w-full h-16 mb-4">
          <div className="animate-pulse bg-gray-200 shadow rounded-md w-full ml-4"></div>
        </div>
        <div className="flex flex-row  w-full h-16 mb-4">
          <div className="animate-pulse bg-gray-200 shadow rounded-md w-full ml-4"></div>
        </div>
        <div className="flex flex-row  w-full h-16 mb-4">
          <div className="animate-pulse bg-gray-200 shadow rounded-md w-full ml-4"></div>
        </div>
      </WidgetBody>
      <WidgetFooter>
        <div className="flex flex-row  w-full h-16 mb-4">
          <div className="animate-pulse bg-gray-200 shadow rounded-md w-full ml-4"></div>
        </div>
      </WidgetFooter>
    </WidgetContainer>
  );
};

export default AdsWidgetLoader;
