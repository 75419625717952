import React, { useContext } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import GenericContainer from "../../../common/GenericContainer/GenericContainer";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { PhotographIcon } from "@heroicons/react/outline";
import IconButton from "../../../uiwrappers/IconButton/IconButton";
import { getOneRandomTopic } from "../../marketing/DigitalMedia/utils";
import { useRouter } from "next/router";
import { getPathname } from "../CreateAPostModal/utils";
import ImageUpload from "../../../uiwrappers/ImageUpload/ImageUpload";
import { useFeatureGating } from "../../../../hooks/useFeatureGating/useFeatureGating";
import {
  ActionTiming,
  Features,
} from "../../../../hooks/useFeatureGating/utils";
import useChurnModal from "../../../../hooks/useChurnModal/useChurnModal";

export default function NewPost({
  handleImagesSelected,
  createPostModalOpen,
  setCreatePostModalOpen,
  location,
  handleGeneratePost,
  newMessage,
  attachedPhotos,
}: any) {
  const router = useRouter();
  const {
    basicInfo,
    userPhoto,
    reviewInfo,
    userPersonalBlogs,
    userScheduleBlogs,
    setSavedPost,
    featureUsage,
  } = useContext(DesktopContext);
  const { renderChurnModal, shouldOpenChurnModal } = useChurnModal();

  const { renderFeatureGateModal, checkFeatureUsage } = useFeatureGating();

  async function handleAutoGeneratePost() {
    if (shouldOpenChurnModal()) return;

    setCreatePostModalOpen(true);
    await handleGeneratePost();
  }

  function startGenerateMedia() {
    const hasHitLimit = checkFeatureUsage(
      featureUsage,
      Features.DIGITAL_MEDIA_CREATE,
      ActionTiming.Before
    );
    if (hasHitLimit) {
      return;
    }

    setSavedPost({
      text: newMessage,
      photos: attachedPhotos,
    });

    const topic = getOneRandomTopic(
      userPhoto,
      reviewInfo,
      userPersonalBlogs,
      userScheduleBlogs,
      basicInfo
    );

    const origin = getPathname(router);
    router.push({
      pathname: "/marketing/digital-media-create",
      query: {
        origin,
        postModalOpen: createPostModalOpen,
        topic,
      },
    });
  }

  let customStyle;
  if (location === "social-page") {
    customStyle = "p-4 mt-4 shadow";
  } else if (location === "dashboard") {
    customStyle = "mt-4";
  }

  function startAPostHandler() {
    if (shouldOpenChurnModal()) return;
    setCreatePostModalOpen(true);
  }

  return (
    <>
      {renderChurnModal()}
      {renderFeatureGateModal()}

      <GenericContainer customStyle={customStyle}>
        <div className="flex space-x-4">
          <div className="flex flex-col w-full">
            <div className="flex justify-between border-b cursor-pointer sm:pb-0 pb-4">
              <div
                className="flex w-full pb-0 sm:pb-4"
                onClick={startAPostHandler}
              >
                <div className="flex flex-shrink-0 relative w-10 h-10 rounded-full mr-3">
                  {basicInfo?.logo && (
                    <img
                      src={basicInfo.logo}
                      alt="logo"
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  )}
                </div>
                <div className="flex w-full text-gray-600">Start a post...</div>
              </div>

              <ImageUpload
                openModal={startAPostHandler}
                processImages={handleImagesSelected}
                multiple={false}
              >
                <div className="sm:hidden relative flex flex-shrink-0 items-center justify-center w-24 h-24 bg-gray-50 border border-gray-300 rounded cursor-pointer">
                  <PhotographIcon className="text-gray-600 w-8 h-8 heroicon-stroke-w-1" />
                  <div className="absolute bottom-1 right-1">
                    <IconButton size="xs" color="gray" icon="plus" />
                  </div>
                </div>
              </ImageUpload>
            </div>

            <div className="flex w-full pt-5 sm:space-x-8 space-x-6">
              <PrimaryLink
                text="Generate Post"
                size="lg"
                icon="sparkles"
                onClickFunc={handleAutoGeneratePost}
                customStyle="whitespace-nowrap"
              />
              <PrimaryLink
                text="Generate Media"
                size="lg"
                icon="generateMedia"
                onClickFunc={startGenerateMedia}
                customStyle="whitespace-nowrap"
              />
            </div>
          </div>
          <ImageUpload
            openModal={startAPostHandler}
            processImages={handleImagesSelected}
            multiple={true}
          >
            <div className="hidden sm:relative sm:flex sm:flex-shrink-0 items-center justify-center w-24 h-24 bg-gray-50 border border-gray-300 rounded cursor-pointer">
              <PhotographIcon className="text-gray-600 w-8 h-8 heroicon-stroke-w-1" />
              <div className="absolute bottom-1 right-1">
                <IconButton size="xs" color="gray" icon="plus" />
              </div>
            </div>
          </ImageUpload>
        </div>
      </GenericContainer>
    </>
  );
}
