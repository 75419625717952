// use ICampaignMetric to generate methods to calcuate various metrics

import {
  Detail,
  ICampaignMetric,
} from "../../hooks/useCampaignMetricFetch/types";

export class CampaignMetricsAnalyticService {
  constructor(private campaignMetrics: ICampaignMetric) {
    this.campaignMetrics = campaignMetrics;
  }
  getTotalCost(): string | 0 {
    return this.campaignMetrics?.cost?.total.toFixed(2) || 0;
  }
  getTotalClicks(): number {
    return this.campaignMetrics?.clicks?.total || 0;
  }
  getTotalConversions(): number {
    return this.campaignMetrics?.conversions?.total || 0;
  }
  getLastTwoDifference(costDetails: Detail[]): number {
    const last = costDetails?.[0]?.value || 0;
    const secondLast = costDetails?.[1]?.value || 0;
    return last - secondLast;
  }
  getLastTwoPeriodCostDifference(): string {
    const costDetails = this.campaignMetrics?.cost?.details;
    return this.getLastTwoDifference(costDetails).toFixed(2);
  }
  getLastTwoPeriodClicksDifference(): number {
    const clicksDetails = this.campaignMetrics?.clicks?.details;
    return this.getLastTwoDifference(clicksDetails);
  }
  getLastTwoPeriodConversionsDifference(): number {
    const conversionsDetails = this.campaignMetrics?.conversions?.details;
    return this.getLastTwoDifference(conversionsDetails);
  }
}
