import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LoadingSuccessGraphic } from "../LoadingSuccessGraphic/LoadingSuccessGraphic";

export default function ConnectionModal({
  title,
  isOpen,
  setIsOpen,
  loading,
  success,
  celebrate,
}: any) {
  return (
    <>
      {isOpen ? (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setIsOpen}
          >
            <div className="flex items-center -mt-12 justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                unmount={true}
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-10 sm:my-8 sm:align-middle sm:w-2/3 lg:w-1/2 xl:w-2/5 sm:p-6">
                  <div className="flex flex-col justify-center items-center px-24 py-28">
                    {celebrate && (
                      <>
                        <img src="/lottie-checklist.gif" className="w-40" />
                        <div className="text-h3-medium">{title}</div>
                      </>
                    )}
                    {!celebrate && (
                      <>
                        <LoadingSuccessGraphic
                          loading={loading}
                          success={success}
                        />
                        <p className="mb-3 text-h3-normal">
                          {success
                            ? "Connected to Google!"
                            : "Connecting to Google..."}
                        </p>
                      </>
                    )}
                  </div>
                  <button className="absolute"></button>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </>
  );
}
