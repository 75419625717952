export default function UploadPhotoIcon() {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 5.87125H5C3.93913 5.87125 2.92172 6.29267 2.17157 7.04282C1.42143 7.79296 1 8.81038 1 9.87125V29.8712M1 29.8712V33.8712C1 34.9321 1.42143 35.9495 2.17157 36.6997C2.92172 37.4498 3.93913 37.8712 5 37.8712H29C30.0609 37.8712 31.0783 37.4498 31.8284 36.6997C32.5786 35.9495 33 34.9321 33 33.8712V25.8712M1 29.8712L10.172 20.6992C10.9221 19.9494 11.9393 19.5281 13 19.5281C14.0607 19.5281 15.0779 19.9494 15.828 20.6992L21 25.8712M33 17.8712V25.8712M33 25.8712L29.828 22.6992C29.0779 21.9494 28.0607 21.5281 27 21.5281C25.9393 21.5281 24.9221 21.9494 24.172 22.6992L21 25.8712M21 25.8712L25 29.8712M29 5.87125H37M33 1.87125V9.87125M21 13.8712H21.02"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
