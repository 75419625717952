import React, { useState, useContext, useEffect } from "react";
import "chartjs-adapter-date-fns";
import WidgetContainer from "../primaryrender/dashboard/WidgetContainer/WidgetContainer";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import AnalyticsWidgetFooter from "./AnalyticsWidgetFooter";
import { AnalyticsType } from "../../contexts/types";
import AnalyticsWidgetBody from "./AnalyticsWidgetBody";

export const bodyContent: Map<AnalyticsType, { title: string; body: string }> =
  new Map([
    [
      AnalyticsType.MapPack,
      {
        title: "Google Map Appearances",
        body: "Times your Google Business Profile appeared in local search results between",
      },
    ],
    [
      AnalyticsType.Keywords,
      {
        title: "Your Target Keywords",
        body: "Your SEO-optimized website is currently targeting the following keyword categories:",
      },
    ],
  ]);

export const footerContent: Map<
  AnalyticsType,
  { title: string; body: string }
> = new Map([
  [
    AnalyticsType.MapPack,
    {
      title: "Maximize your Google Business Profile",
      body: "Navigate the complexities of SEO and learn how Topline Pro is pushing you to the top of the Map Pack",
    },
  ],
  [
    AnalyticsType.Keywords,
    {
      title: "Target the right keywords by adding services",
      body: "Topline Pro makes it easy for you to tell Google what services your business offers",
    },
  ],
]);

const AnalyticsWidget = ({
  formattedLongTimePeriod,
}: {
  formattedLongTimePeriod: string;
}) => {
  const { googleImpressionsCount, googleSearchKeyWords } =
    useContext(DesktopContext);

  const [selectedWidget, setSelectedWidget] = useState<number>(0);
  const [tabs, setTabs] = useState<AnalyticsType[]>([]);

  const styles = {
    current:
      "horizontal-subnav border-blue-600 text-blue-600 inline-flex items-center pb-4 px-1 mr-8 border-b-2 text-sm-medium cursor-pointer whitespace-nowrap",
    notCurrent:
      "horizontal-subnav border-transparent text-gray-500 pb-4 mr-8 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 border-b-2 text-sm-medium cursor-pointer whitespace-nowrap",
  };

  useEffect(() => {
    const tabsToAdd: AnalyticsType[] = [];
    if (googleImpressionsCount && googleImpressionsCount >= 25)
      tabsToAdd.push(AnalyticsType.MapPack);
    if (googleSearchKeyWords.size) tabsToAdd.push(AnalyticsType.Keywords);
    setTabs(tabsToAdd);
    if (tabsToAdd.length - 1 < selectedWidget)
      setSelectedWidget(tabsToAdd.length == 0 ? 0 : tabsToAdd.length - 1); //This is to change the selected widget when the number of tabs change
  }, [googleImpressionsCount]);
  return (
    <>
      {tabs.length != 0 && (
        <WidgetContainer>
          {/* Widget header horizontal nav bar */}
          <div>
            {tabs.map((tab, index) => (
              <a
                key={tab}
                className={
                  selectedWidget === index ? styles.current : styles.notCurrent
                }
                onClick={() => setSelectedWidget(index)}
              >
                {tab}
              </a>
            ))}
            <div className="border-b -mt-px -mx-6"></div>
          </div>

          {/* Widget body */}
          <AnalyticsWidgetBody
            type={tabs[selectedWidget]}
            formattedLongTimePeriod={formattedLongTimePeriod}
          />

          {/* Widget footer */}
          <AnalyticsWidgetFooter type={tabs[selectedWidget]} />
        </WidgetContainer>
      )}
    </>
  );
};

export default AnalyticsWidget;
