import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";

import { useRouter } from "next/router";
import EmptyState from "../../../uiwrappers/EmptyState/EmptyState";
import useIsMobile from "../../../../hooks/useIsMobile";

export default function PaymentsWidgetIncompleteSetup() {
  const router = useRouter();
  const isMobile = useIsMobile();

  async function connectToStripe() {
    router.push("/payments-start");
  }

  return (
    <>
      <div>
        <div className="flex flex-col w-full items-center">
          <div className="flex sm:flex-row flex-col justify-between items-center w-full">
            <h1 className="text-h2-bold text-gray-900">
              Get paid on Topline Pro
            </h1>
            <div className="mt-2 sm:mt-0">
              <PrimaryButton
                onClickFunc={connectToStripe}
                size="sm"
                text="Continue setup"
              />
            </div>
          </div>
          <EmptyState
            header="You haven’t finished setting up your account"
            subheader=""
            icon="payment"
            customStyle="pb-4"
          />
        </div>
      </div>
    </>
  );
}
