interface WidgetFeatureProps {
  feature: string;
  FeatureIcon: (_props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
}

const WidgetFeature: React.FC<WidgetFeatureProps> = ({
  feature,
  FeatureIcon,
}) => {
  return (
    <div className="flex flex-row items-center my-3">
      <FeatureIcon className="flex w-6 h-6 text-white  mr-2" />
      <div className="text-body-normal">{feature}</div>
    </div>
  );
};

export default WidgetFeature;
